import React from "react";
import PropTypes from "prop-types"
import MD5 from "crypto-js/md5"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import CustomButton from "components/Buttons/custom"
import IconButton from "components/CustomButtons/IconButton"
import RenderOptions from "./RenderOptions"

//STYLES
import "assets/css/components/react-datepicker.css"

//FUNCTIONS
import { customApp, translate } from "functions/"


function FilterComponent(props) {
    const {
        searchs,
        db
    } = reduxStore.getState();
    const [viewSearch, setviewSearch] = React.useState(false);
    const [searchValues, setSearch] = React.useState({ ...JSON.parse(initialIndicators) })
    const [searchID, setSearchId] = React.useState(props.nodeId ? props.nodeId : MD5(`${window.location.pathname}-${window.location.hash}`).toString())

    const mounted = React.useRef(true)
    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        let newValue = {
            ...JSON.parse(initialIndicators),
            ...searchs[searchID] || {},
        }
        if (JSON.stringify(newValue) !== JSON.stringify(searchValues) && mounted.current) {
            setSearch(newValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchID])


    React.useEffect(() => {
        if (props.onChange) {
            props.onChange({
                ...searchValues || {}
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValues]);

    React.useEffect(() => {
        if (mounted.current)
            setSearchId(props.nodeId ? props.nodeId : MD5(`${window.location.pathname}-${window.location.hash}`).toString())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nodeId])

    const removeFilter = () => {
        let removeFilter = {
            ...reduxStore.getState().searchs,
        }

        if (removeFilter[searchID]) {
            delete removeFilter[searchID]
        }

        props.reduxFunction("IMMEDIATE", "SET_SEARCH", removeFilter)
        if (mounted.current)
            setSearch({ ...JSON.parse(initialIndicators) })
        if (props.onSearch)
            props.onSearch()
    }


    let customTitles = {}

    if (
        props.nodeId &&
        db.cards &&
        db.cards[props.nodeId] &&
        db.cards[props.nodeId].type === 'plan' &&
        db.cards[props.nodeId].refs &&
        (
            db.cards[props.nodeId].ref1Title
            || db.cards[props.nodeId].ref2Title
        )
    ) {

        customTitles = {
            ...customTitles,
            ...db.cards[props.nodeId].ref1Title ? {
                ref1Title: db.cards[props.nodeId].ref1Title
            } : {},
            ...db.cards[props.nodeId].ref2Title ? {
                ref2Title: db.cards[props.nodeId].ref2Title
            } : {},
        }
    } else if (props.nodeId &&
        db.cards &&
        db.cards[props.nodeId] &&
        db.cards[props.nodeId]._planId &&
        db.cards[db.cards[props.nodeId]._planId].refs &&
        (
            db.cards[db.cards[props.nodeId]._planId].ref1Title
            || db.cards[db.cards[props.nodeId]._planId].ref2Title
        )
    ) {
        customTitles = {
            ...customTitles,
            ...db.cards[db.cards[props.nodeId]._planId].ref1Title ? {
                ref1Title: db.cards[db.cards[props.nodeId]._planId].ref1Title
            } : {},
            ...db.cards[db.cards[props.nodeId]._planId].ref2Title ? {
                ref2Title: db.cards[db.cards[props.nodeId]._planId].ref2Title
            } : {},
        }
    }

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    if (hashs[0].length !== 36) {
        hashs[0] = null
    }


    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <div
                style={{
                    position: 'relative', width: '100%', padding: '0px', marginBottom: '15px',
                }}
            >
                {props.fields?.map((ff, ffi) => <div key={`${ff}_${ffi}`} style={{ position: "relative" }}><RenderOptions nodeId={searchID} field={ff} customTitles={customTitles} onChange={props.onChange ? (appliedFilters) => {
                    if (mounted.current)
                        setSearch(appliedFilters)
                } : null} /></div>)}

                {/* FINAL FILTER PARAMETERS */}
                {JSON.stringify(searchValues) !== initialIndicators ?
                    <div
                        style={{
                            padding: '5px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                        onClick={() => { setviewSearch(!viewSearch) }}
                    >
                        <IconButton reverse icon={'clear_all'} text={translate("$__removeFilter", "*")}
                            onClick={() => {
                                removeFilter()

                            }} />
                    </div> : <></>}
                {props?.preffers?.searchButton ?
                    <CustomButton
                        color={customApp('menu')}
                        icon={'search'}
                        onClick={() => {
                            props.reduxFunction("ASYNC", "SET_SEARCH", {
                                ...reduxStore.getState().searchs,
                                [searchID]: {
                                    ...JSON.parse(initialIndicators),
                                    ...searchValues
                                },
                            })
                            if (props.onSearch)
                                props.onSearch()
                        }}
                        size={'15px'}
                        title={`${translate("$__search")}`}
                        text={`${translate("$__search")}`}
                    />
                    : <></>
                }
            </div>
        </div>
    );
}

const initialIndicators = JSON.stringify({
    status: {
        all: true,
        notStarted: true,
        awaitAction: true,
        paused: true,
        inProgress: true,
        completed: true,
    },
    priority: {
        all: true,
        'null': true,
        normal: true,
        low: true,
        medium: true,
        high: true,
    },
    risk: {
        all: true,
        'null': true,
        normal: true,
        low: true,
        medium: true,
        high: true,
    },
    complexity: {
        all: true,
        'null': true,
        normal: true,
        low: true,
        medium: true,
        high: true,
    },
    impact: {
        all: true,
        'null': true,
        normal: true,
        low: true,
        medium: true,
        high: true,
    },
})

const mapStateToProps = (store) => ({
    searchs: store.searchs
})

FilterComponent.propTypes = {
    handleDrawerToggle: PropTypes.func,
    sidebarMinimize: PropTypes.func
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(FilterComponent)