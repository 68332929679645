//CORE
import React from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";
import history from "store/history"

//COMPONENTS
import Icon from "components/Icon"
import CustomButton from "components/Buttons/custom";

//@MATERIAL
import Badge from '@material-ui/core/Badge';
import { withStyles } from "@material-ui/core/styles";

//IMAGES
import logoMachen from "assets/img/logoMachen.svg";
import Bright from "assets/icons/BrigthAndConnected";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/MobileNav";

//FUNCTIONS
import { translate, customApp } from "functions/";
import { countUnRead } from "functions/chat";

require('dotenv').config()

function Component(props) {
    const { chat } = reduxStore.getState()
    const { classes } = props
    const [viewModule, setViewModule] = React.useState(null);

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    const openModule = async (route, vm, nodeId, dbProps = 'users') => {
        if (route && (window.location.pathname !== route || vm === viewModule)) {
            history.push(route)
        }


        if (vm === 'chat') {
            props.reduxFunction("ASYNC", "SET_CHAT", {
                ...chat,
                open: true,
                db: dbProps,
                nodeId: nodeId,
            })
        } else {
            setViewModule(vm)
            props.reduxFunction("ASYNC", "SET_CHAT", {
                ...chat,
                open: false,
            })
        }
        if (props.menuExpand)
            props.menuExpand()
    }

    const count = countUnRead()

    if (!props.expanded)
        return (
            <div className={classes.rootHide}>
                <div
                    className={classes.mainButton}
                    id="sideBar_logoMini"
                >
                    <img alt="MACHEN_IMAGE" src={logoMachen}
                        className={classes.logoImg}
                        style={{
                            width: "111px",
                            marginLeft: "15px"
                        }}
                        onClick={() => {
                            props.menuExpand()
                        }}
                    />
                    <CustomButton
                        title={translate(`$__showMenu`, 1)}
                        color={customApp('menu')}
                        icon={'menu'}
                        onClick={props.menuExpand ? () => {
                            props.menuExpand()
                        } : null}
                        transparent
                    />
                </div>
            </div>
        )

    return (
        <div className={classes.root}>
            <div className={classes.bar}>
                <div className={classes.topLogo}
                    id="sideBar_logoMini"
                >
                    <img alt="MACHEN_IMAGE" src={logoMachen}
                        className={classes.logoImg}
                        style={{
                            width: "111px",
                            marginLeft: "15px"
                        }}
                    />
                    <CustomButton
                        title={translate(`$__hideMenu`, 1)}
                        color={customApp('color')}
                        icon={'menu'}
                        onClick={props.menuExpand ? () => {
                            props.menuExpand()
                        } : null}
                        transparent
                    />
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top: '120px',
                        bottom: '0px',
                        overflowX: 'auto',
                        width: '100%'
                    }}
                >
                    <div className={classes.item} id="sideBar_button_dashboard">
                        <div
                            className={window.location.pathname === '/d' ? classes.itemButtomActive : classes.itemButtom}
                            onClick={() => {
                                openModule('/d', 'dashboard')
                            }
                            }
                        >
                            <Icon
                                icon="dashboard"
                                badgeColor={'primary'}
                                color={'parent'}
                                badgeVisible
                                title="$__dashboard"
                                size={'27px'}
                                style={{
                                    marginBottom: '7px',
                                    marginRight: '15px'
                                }}
                            />
                            {translate(`$__dashboard`, '*')}
                        </div>
                    </div>
                    <div className={classes.item} id="sideBar_button_chat">
                        <div className={chat.open ? classes.itemButtomActive : classes.itemButtom}
                            onClick={() => {
                                if (props.menuExpand)
                                    props.menuExpand()
                                props.reduxFunction("ASYNC", "SET_CHAT", {
                                    ...chat,
                                    open: !chat.open,
                                })
                            }
                            }
                        >
                            <Badge
                                overlap="rectangular"
                                badgeContent={count && count.total ? count.total : 0} color="secondary">
                                <Icon
                                    icon={'chat'}
                                    color={'parent'}
                                    title="$__chat"
                                    size={'27px'}
                                    style={{
                                        marginBottom: '7px',
                                        marginRight: '15px'
                                    }}
                                />
                            </Badge>
                            {translate(`$__chat`, '*')}
                        </div>
                    </div>
                    <div className={classes.separator}></div>
                    <div className={classes.item} id="sideBar_button_StrategyAndPlans">
                        <div className={window.location.pathname === '/sp' ? classes.itemButtomActive : classes.itemButtom}
                            onClick={() => {
                                openModule('/sp', 'StrategyAndPlans')
                            }
                            }
                        >
                            <Icon
                                icon="view_comfy"
                                badgeColor={'primary'}
                                color={'parent'}
                                badgeVisible
                                title={translate('$__StrategyAndPlans')}
                                size={'27px'}
                                style={{
                                    marginBottom: '7px',
                                    marginRight: '15px'
                                }}
                            />
                            {translate(`$__StrategyAndPlans`, '*')}
                        </div>
                    </div>
                    <div className={classes.item} id="sideBar_button_myTasks">
                        <div className={window.location.pathname === '/t' ? classes.itemButtomActive : classes.itemButtom}
                            onClick={() => {
                                openModule('/t', 'myTasks')
                            }
                            }
                        >
                            <Icon
                                icon="task"
                                badgeColor={'primary'}
                                color={'parent'}
                                badgeVisible
                                title={translate('$__mytasks')}
                                size={'27px'}
                                style={{
                                    marginBottom: '7px',
                                    marginRight: '15px'
                                }}
                            />
                            {translate(`$__mytasks`, '*')}
                        </div>
                    </div>
                    <div className={classes.item} id="sideBar_button_library">
                        <div className={window.location.pathname === '/library' ? classes.itemButtomActive : classes.itemButtom}
                            onClick={() => {
                                openModule('/library', 'library')
                            }
                            }
                        >
                            <Icon
                                icon="folder"
                                badgeColor={'primary'}
                                color={'parent'}
                                badgeVisible
                                title={translate('$__library')}
                                size={'27px'}
                                style={{
                                    marginBottom: '7px',
                                    marginRight: '15px'
                                }}
                            />
                            {translate(`$__library`, '*')}
                        </div>
                    </div>
                    <div className={classes.item} id="sideBar_button_brightandconnected">
                        <div className={window.location.pathname === '/bright-and-connected' ? classes.itemButtomActive : classes.itemButtom}
                            onClick={() => {
                                openModule('/bright-and-connected', 'bright-and-connected')
                            }
                            }
                        >
                            <Icon
                                icon={Bright}
                                badgeColor={'primary'}
                                color={'parent'}
                                badgeVisible
                                title="BRIGHT & CONNECTED"
                                size={'27px'}
                                style={{
                                    marginBottom: '7px',
                                    marginRight: '15px'
                                }}
                            />
                            BRIGHT & CONNECTED
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (store, ownProps) => ({
    store, ownProps
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
