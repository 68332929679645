//FUNCTIONS
import { customApp } from "functions";

const sidebarStyle = theme => ({
  root: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100vh',
    zIndex: 100
  },
  rootHide: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: "center",
    zIndex: 100
  },
  topLogo: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: "center",
    zIndex: 100,
    justifyContent: "space-between"
  },
  bar: {
    backgroundColor: customApp('menu'),
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100
  },
  item: {
    height: '66px',
    alignItems: 'center',
    width: '100%',
    display: 'flex',
  },
  itemButtom: {
    padding: '7px',
    color: customApp('color', 0.8),
    '&:hover': {
      color: customApp('color', 1),
      backgroundColor: 'rgba(255,255,255,0.1)',
      borderRadius: '7px',
    },
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  itemButtomActive: {
    width: '100%',
    padding: '7px',
    color: customApp('color', 1),
    backgroundColor: 'rgba(255,255,255,0.2)',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  themeExample: {
    [theme.breakpoints.up("md")]: {
      left: "auto !important",
      right: "0 !important"
    },
    [theme.breakpoints.down("sm")]: {
      left: "0  !important",
      right: "auto !important"
    }
  },
  module: {
    position: 'fixed',
    top: '0px',
    left: '147px',
    width: '70px',
    height: '100vh',
    backgroundColor: 'rgba(246,246,246,1)',
    // boxShadow: '3px 0px 10px 0px rgb(0 0 0 / 22%)',
    borderRight: `solid 4px ${customApp('color')}`,
    padding: '7px',
    //zIndex: '110',
  },
  moduleExpanded: {
    position: 'fixed',
    top: '0',
    left: '147px',
    width: '311px',
    height: '100vh',
    backgroundColor: 'rgba(246,246,246,1)',
    zIndex: '100 !important',
    // boxShadow: '3px 0px 10px 0px rgb(0 0 0 / 22%)',
    borderRight: `solid 4px ${customApp('color')}`,
    padding: '7px',
    //zIndex: 110,
    overflowX: 'auto'
  },
  moduleTitle: {
    width: '100%',
    height: '30px',
    fontSize: '18px',
    color: customApp('color'),
    fontWeight: 'bold',
    paddingLeft: '7px',
    marginTop: '15px'
  },
  sidebarWrapperWithPerfectScrollbar: {
    // overflow: "hidden !important"
  },
  separator: {
    position: 'relative',
    float: 'left',
    width: '100%',
    padding: '7px',
    borderTop: `1px solid ${customApp('medium', 0.5)}`,
    marginTop: '15px',
    marginBottom: '0px',
    height: '1px'
  },
  mainButton: {
    // marginTop: '10px',
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    // padding: '10px 0',
    alignItems: 'center',
  },
  logoImg: {
    width: 111,
    marginLeft: "15px",
    justifyContent: 'center'
  },
  user: {
    zIndex: '150 !important'
  }
});

export default sidebarStyle;
