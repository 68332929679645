import React, { useEffect } from "react"

//REDUX
import * as reduxActions from "store/actions"
import reduxStore from "store"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//COMPONENTS
import CustomButton from "components/Buttons/custom"
import Navigation from "components/Navigation"
import ProgressCircular from "components/Progress/circular"

//@MATERIAL
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Modal from '@material-ui/core/Modal'

import {
    updateCardv2,
    cardMoveTo,
    getChildrens
} from "functions/cards"

import {
    customApp,
    translate,
} from "functions/"


function MoveCards(props) {
    const [data, setData] = React.useState({})
    const [copyRel, setCopyRel] = React.useState({})
    const [duplicateLoader, setDuplicateLoader] = React.useState(false)

    React.useEffect(() => {
        if (props.selectedCards) {
            setData(props.selectedCards)
        } else {
            setData({})
        }
        return () => {
            setData({})
        }
    }, [props.selectedCards])


    if (JSON.stringify(data) === "{}" || !data)
        return (<React.Fragment></React.Fragment>)


    const definePermission = (cardType) => {
        switch (cardType) {
            case 'plan':
                return 0;
            case 'guideline':
                return 1;
            case 'objective':
                return 2;
            case 'goal':
                return 3;
            case 'iniciative':
                return 4;
            case 'task':
                return 5;
            default: return 4
        }
    }

    const duplicateCard = async () => {
        const typeArr = ['plan', 'guideline', 'objective', 'goal', 'iniciative', 'task']

        for (let i = 0; i < data.length; i++) {
            const card = reduxStore.getState().db.cards[data[i]]

            try {
                let newData = {}

                newData = {
                    cardId: card._id,
                    newRel: copyRel._id,
                    oldRel: card._parent,
                    planId: reduxStore.getState().db.cards[copyRel._id]?._planId ? reduxStore.getState().db.cards[copyRel._id]._planId : reduxStore.getState().db.cards[copyRel._id]?.type === 'plan' ? copyRel._id : null,
                    // / reduxStore.getState().db.cards[copyRel._id]._planId ? reduxStore.getState().db.cards[copyRel._id]._planId : reduxStore.getState().db.cards[copyRel._id].type === 'plan' ? copyRel._id :
                }

                // props.reduxFunction("ASYNC", "SET_DB", {
                //     ...reduxStore.getState().db,
                //     cards: {
                //         ...reduxStore.getState().db.cards,
                //         [card._id]: {
                //             ...reduxStore.getState().db.cards[card._id],
                //             _parent: copyRel._id,
                //             _users: {
                //                 ...reduxStore.getState().db.cards[card._id]._users,
                //             }
                //         }
                //     }
                // })

                const request = cardMoveTo(props, newData)

                if (request && definePermission(card.type) !== definePermission(copyRel.type) + 1) {

                    let newData = {}
                    let childrens = await getChildrens(card._id)
                    childrens = (Object.keys(childrens) || [])
                    const numberOfHousesJumped = definePermission(copyRel.type) + 1 - definePermission(card.type)

                    if (childrens.length > 0) {
                        childrens = childrens.map(async (item) => {
                            const cardChildren = reduxStore.getState().db.cards[item]
                            let typeChange = typeArr[definePermission(cardChildren.type) + numberOfHousesJumped]

                            if (definePermission(copyRel.type) === 4) {
                                typeChange = typeArr[4]
                            }

                            if (definePermission(copyRel.type) === 3) {
                                typeChange = typeArr[4]
                            }

                            if (definePermission(copyRel.type) === 5) {
                                typeChange = typeArr[5]
                            }

                            return {
                                _id: item,
                                type: typeChange
                            }
                        })
                    }

                    console.log(reduxStore.getState().db.cards[copyRel._id])

                    newData = {
                        [card._id]: {
                            ...reduxStore.getState().db.cards[card._id],
                            _parent: copyRel._id,
                            _planId: reduxStore.getState().db.cards[copyRel._id]?._planId ? reduxStore.getState().db.cards[copyRel._id]._planId : reduxStore.getState().db.cards[copyRel._id]?.type === 'plan' ? copyRel._id : null,
                        }
                    }


                    const resolvedChildrens = await Promise.all(childrens);


                    await updateCardv2(props, [{
                        _id: card._id,
                        type: typeArr[definePermission(copyRel.type) + 1]
                    }, ...resolvedChildrens])



                    props.reduxFunction("ASYNC", "SET_DB", {
                        ...reduxStore.getState().db,
                        cards: {
                            ...reduxStore.getState().db.cards,
                            ...newData
                        }
                    })

                }
                if (request) {
                    let copyResult = true
                    if (copyResult) {
                        setTimeout(async () => {
                            if (i === data.length - 1) {
                                setDuplicateLoader(false)
                                props.reduxFunction("ASYNC", "SET_CONTROL", {
                                    ...reduxStore.getState().control,
                                    selectedCards: null,
                                })
                            }
                        }, 2000)
                    }
                }

            } catch (e) {
                console.log("ERROR", e)
            }
        }


    }


    return (
        <Modal
            open={true}
            onClose={() => {
                props.reduxFunction("ASYNC", "SET_CONTROL", {
                    ...reduxStore.getState().control,
                    selectedCards: null,
                })
            }
            }
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <ClickAwayListener
                onClickAway={() => {
                    props.reduxFunction("ASYNC", "SET_CONTROL", {
                        ...reduxStore.getState().control,
                        selectedCards: null,
                    })
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        right: '0px',
                        bottom: '0px',
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                >

                    <div style={{
                        position: "relative",
                        width: '400px',
                        backgroundColor: '#FFFFFF',
                        margin: 'auto',
                        boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.2)",
                    }}>
                        <div style={{
                            position: "relative",
                            width: '400px',
                            backgroundColor: '#FFFFFF',
                            padding: '9px',
                            height: "40px",
                            margin: 'auto',
                            background: customApp('ColumnTitleColor'),
                        }}>
                            <div
                                style={{
                                    fontSize: "14px",
                                    color: customApp("color")
                                }}
                            >{translate("$__send")}</div>
                            <div style={{
                                position: "absolute",
                                right: 5,
                                top: 5
                            }}>
                                <CustomButton
                                    id={`close_duplicate`}
                                    title={translate(`$__close`, "*")}
                                    color={customApp("color")}
                                    icon={"close"}
                                    onClick={() => {
                                        props.reduxFunction("ASYNC", "SET_CONTROL", {
                                            ...reduxStore.getState().control,
                                            selectedCards: null,
                                        })
                                    }}
                                    size={"25px"}
                                    transparent
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                position: "relative",
                                padding: '9px',
                            }}
                        >
                            <Navigation
                                onSelect={(e) => {
                                    console.log(e)
                                    setCopyRel(e)
                                }}
                                ignoreCards={data}
                                ignoreTypes={['chatGroup']}
                            />

                            <div
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    display: "grid"
                                }}
                            >
                                {!duplicateLoader ?

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginTop: 40
                                        }}
                                    >
                                        <CustomButton
                                            title={translate(`$__cancel`, 1)}
                                            text={translate(`$__cancel`, 1)}
                                            color={"lightgray"}
                                            icon={"cancel"}
                                            onClick={() => {
                                                props.reduxFunction("ASYNC", "SET_CONTROL", {
                                                    ...reduxStore.getState().control,
                                                    selectedCards: null,
                                                })
                                            }}
                                            size={"12px"}
                                            textSize={"12px"}
                                        />

                                        {Object.keys(copyRel).length > 0 && <CustomButton
                                            title={translate(`$__send`, 1)}
                                            text={translate(`$__send`, 1)}
                                            color={customApp("menu")}
                                            icon={"send"}
                                            onClick={() => {
                                                setDuplicateLoader(true)
                                                setTimeout(() => {
                                                    duplicateCard()
                                                }, 200);
                                            }}
                                            size={"12px"}
                                            textSize={"12px"}
                                        />}

                                        {/* <CustomButton
                                            title={translate(`$__confirmMoveCard`, 1)}
                                            text={translate(`$__confirmMoveCard`, 1)}
                                            color={customApp("menu")}
                                            icon={"dynamic_feed"}
                                            onClick={() => {
                                                moveCard(true)
                                            }}
                                            size={"12px"}
                                            textSize={"12px"}
                                            transparent
                                        /> */}
                                    </div>
                                    :
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: 40,
                                        }}
                                    >
                                        <ProgressCircular />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </ClickAwayListener>
        </Modal>
    )
}

//REACT
const mapStateToProps = (store) => ({
    selectedCards: store.controls.selectedCards,
    cards: store.db.cards
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MoveCards)