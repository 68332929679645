import React, { useRef, useEffect } from "react"
import moment from "moment-timezone"
import styled from "styled-components"
import MD5 from "crypto-js/md5"

//REDUX
import * as reduxActions from "store/actions"
import history from "store/history"
import reduxStore from "store"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//COMPONENTS
import Badge from "components/Icon/Badge"
import CardTags from "components/Tags/Card"
import CustomButton from "components/Buttons/custom"
import HeaderWithIcon from "components/Header/card"
import Icon from "components/Icon"
import IconButton from "components/CustomButtons/IconButton"
import QuickUsersList from "components/User/avatarList"
import Timer from "components/Timer/timer"

//@MATERIAL
import Avatar from "@material-ui/core/Avatar"
import Tooltip from "@material-ui/core/Tooltip"

//DEPENDENCIES
import FakeExpanded from "./fakeCardExpanded"
import CardExpanded from "./cardExpanded"
import UseTemplate from "./useTemplate"

//@MATERIAL
import { makeStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/Card.js"

//FUNCTIONS
import apims from "apims"

import {
    cardStatus,
    deleteCard,
    getCardColor,
    getUserAccess,
    kpiTimerStart,
    restoreCard,
    saveMATRIX,
    selectSWOT,
    setMyDay,
    updateCard,
    updateCardStatus,
} from "functions/cards"

import {
    action
} from "functions/kanban"

import {
    Highlighted,
    appAlert,
    customApp,
    favoriteNode,
    getAppAccess,
    getDate,
    translate,
} from "functions/"

function Card(props) {
    const classes = useStyles()
    const { db, session } = reduxStore.getState()
    const data = props.cardId && db.cards[props.cardId] ? db.cards[props.cardId] : props.data?._id && db.cards[props.data?._id] ? db.cards[props.data._id] : {}
    const [isExpanded, setExpanded] = React.useState(false)
    const [globalExpanded, setGlobalExpanded] = React.useState(props.cardsExpanded)
    const [useTemplate, setUseTemplate] = React.useState(false)
    const [showMoreButton, setShowMoreButton] = React.useState(false)
    const [showPrivateCard, setShowPrivateCard] = React.useState(!props.unlockedPrivates[data._id] ? false : true)
    const [cardColor, setCardColor] = React.useState(props.cardColorPicker ? getCardColor(data) : {})
    const [combine, setCombine] = React.useState(false)
    const [checked, setChecked] = React.useState(false)
    const [search, setSearch] = React.useState({})

    const mounted = React.useRef(true)

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const searchID = MD5(`${window.location.pathname}-${window.location.hash}`).toString()
    const cRef = React.createRef();

    const sizeRef = React.useRef(0)

    const onResize = () => {
        if (props.onResize && cRef && cRef.current && cRef.current.clientHeight) {
            let newSize = cRef.current.clientHeight
            if (props.separator)
                newSize = newSize + 55
            if (sizeRef.current !== newSize) {
                // if (data.name === "Coffe")
                // console.log(newSize)
                sizeRef.current = newSize
                // setTimeout(() => {
                props.onResize(newSize, isExpanded)

                // }, 10)
            }
        }
    }

    useEffect(() => {
        if (cRef.current && mounted.current) {
            onResize()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpanded, props.manualExpanded, combine]);

    useEffect(() => {
        if (props.onResize && cRef && cRef.current) {
            const _config = { attributes: true, childList: true, subtree: true };
            const _observer = new MutationObserver(() => {
                onResize()
            });
            _observer.observe(cRef.current, _config);

            return () => {
                _observer.disconnect()
            }
        }
        // eslint-disable-next-line
    }, [cRef])



    useEffect(() => {
        if (searchID && props.searchs && props.searchs[searchID] && JSON.stringify(props.searchs[searchID]) !== JSON.stringify(search))
            if (mounted.current)
                setSearch(props.searchs[searchID])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.searchs])

    useEffect(() => {
        if (props?.snapshot?.combineTargetFor)
            if (mounted.current)
                setCombine(true)
        if (!props?.snapshot?.combineTargetFor && combine)
            if (mounted.current)
                setCombine(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.snapshot?.combineTargetFor])


    React.useEffect(() => {
        if (props.unlockedPrivates && props.unlockedPrivates[data._id])
            if (mounted.current)
                setShowPrivateCard(true)
        if (props.unlockedPrivates && !props.unlockedPrivates[data._id])
            if (mounted.current)
                setShowPrivateCard(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.unlockedPrivates])

    React.useEffect(() => {
        if (mounted.current)
            setGlobalExpanded(props.cardsExpanded)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cardsExpanded])

    React.useEffect(() => {
        if (props.onExpand && mounted.current)
            props.onExpand(globalExpanded)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalExpanded])

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')


    React.useEffect(() => {
        if (mounted.current && props.cardColorPicker && hashs && hashs[1] === 'overview')
            setCardColor(getCardColor(data))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cards])



    if (!data)
        return (<React.Fragment></React.Fragment>)

    let AppAccess = getAppAccess()
    let CardAccess = getUserAccess(data._id)



    if (!data || !data || !data._id)
        return null


    const selectable = Object.keys(db.cards)
        .filter(a =>
            db.cards[a]
            && db.cards[a]._selected === true
            && db.cards[a].type
            && db.cards[a].type.indexOf("guideline") > -1
        ).length > 0
        && hashs
        && hashs[1] && hashs[1] === "matrix"
        && db.dataType.filter(fil => fil.name === data.type)[0].module === "swot"
        ? true
        : false

    const selectThis = async () => {
        let selectedType = data.type.indexOf("guideline") > -1 ? "guideline" : data.type === "objective" ? "objective" : data.type === "goal" ? "goal" : "action"
        let selectedCards = {
            ...reduxStore.getState().searchs[searchID]?.selectedCards || {},
        }
        if (hashs[1] !== "matrix") {
            if (selectedCards && selectedCards[selectedType] && selectedCards[selectedType] === data._id) {
                if (selectedType === 'guideline') {
                    delete selectedCards.guideline
                    delete selectedCards.objective
                    delete selectedCards.goal
                    delete selectedCards.action
                }
                if (selectedType === 'objective') {
                    delete selectedCards.objective
                    delete selectedCards.goal
                    delete selectedCards.action
                }
                if (selectedType === 'goal') {
                    delete selectedCards.goal
                    delete selectedCards.action
                }
                if (selectedType === 'action') {
                    delete selectedCards.action
                    delete selectedCards[selectedType]
                }
            } else {
                selectedCards = {
                    ...selectedCards,
                    [data.type.indexOf("guideline") > -1 ? "guideline" : data.type === "objective" ? "objective" : data.type === "goal" ? "goal" : "action"]: data._id
                }
            }

            props.reduxFunction("ASYNC", "SET_SEARCH", {
                ...reduxStore.getState().searchs,
                [searchID]: {
                    ...reduxStore.getState().searchs[searchID] || {},
                    selectedCards: selectedCards
                },
            })
        }

        if (!selectable && hashs[1] !== "matrix") {
            selectThisV2()
            return
        }
        let activeCards = {}
        Object.keys(db.cards).filter(a =>
            db.cards[a]
            && !db.cards[a].deleted
            && (
                db.cards[a]._planId === hashs[0]
                || db.cards[a]._parent === hashs[0]
            )
        ).forEach(a => {
            activeCards = {
                ...activeCards,
                [a]: db.cards[a]
            }
        })
        let cards = { ...db.cards }
        let selected = data._selected ? false : true
        if (db.dataType.filter(fil => fil.name === data.type)[0].module !== "swot") {
            Object.keys(activeCards)
                .filter(a => activeCards[a]._id !== data._id && activeCards[a].type && activeCards[a].type === data.type)
                .forEach(a => {
                    if (activeCards[a]._selected)
                        activeCards[a]._selected = false
                })
        }
        if (data.type.indexOf("guideline") > -1) {
            Object.keys(activeCards).forEach(a => {
                if (activeCards[a]._selected)
                    activeCards[a]._selected = false
            })
        }
        if (data.type.indexOf("objective") > -1) {
            Object.keys(activeCards).filter(a => activeCards[a].type === "goal").forEach(a => {
                if (activeCards[a]._selected)
                    activeCards[a]._selected = false
            })
        }
        props.reduxFunction("ASYNC", "SET_DB", {
            ...db,
            cards: {
                ...cards,
                ...activeCards,
                [data._id]: {
                    ...db.cards[data._id],
                    ...activeCards[data._id],
                    _selected: selected
                }
            }
        })
        if (db.dataType.filter(a => a.name === data.type)[0].module === "swot") {
            saveMATRIX(props)
        }
        if (selected && db.dataType.filter(a => a.name === data.type)[0].module === "matrix") {
            selectSWOT(props, data._id)
        }
    }

    const selectThisV2 = async () => {
        let activeCards = {}

        Object.keys(db.cards).filter(a =>
            db.cards[a]
            && !db.cards[a].deleted
            && (
                db.cards[a]._planId === hashs[0]
                || db.cards[a]._parent === hashs[0]
            )
        ).forEach(a => {
            activeCards = {
                ...activeCards,
                [a]: db.cards[a]
            }
        })

        let cards = { ...db.cards }
        let selected = data._selected ? false : true

        if (db.dataType.filter(fil => fil.name === data.type)[0].module !== "swot") {
            Object.keys(activeCards).filter(a => activeCards[a]._id !== data._id && activeCards[a].type && activeCards[a].type === data.type).forEach(a => {
                if (activeCards[a]._selected)
                    activeCards[a]._selected = false
            })
        }

        if (data.type.indexOf("guideline") > -1) {
            Object.keys(activeCards).forEach(a => {
                if (activeCards[a]._selected)
                    activeCards[a]._selected = false
            })
        } else if (data.type.indexOf("objective") > -1) {
            Object.keys(activeCards).filter(a => activeCards[a].type.indexOf('objective') === -1 || activeCards[a].type.indexOf('guideline') === -1).forEach(a => {
                if (activeCards[a]._selected)
                    activeCards[a]._selected = false
            })
        } else if (data.type.indexOf("goal") > -1) {
            Object.keys(activeCards).filter(a => activeCards[a].type.indexOf('goal') === -1 || activeCards[a].type.indexOf('objective') === -1 || activeCards[a].type.indexOf('guideline') === -1).forEach(a => {
                if (activeCards[a]._selected)
                    activeCards[a]._selected = false
            })
        } else {
            Object.keys(activeCards).forEach(a => {
                if (activeCards[a]._selected)
                    activeCards[a]._selected = false
            })
        }

        props.reduxFunction("ASYNC", "SET_DB", {
            ...db,
            cards: {
                ...cards,
                ...activeCards,
                [data._id]: {
                    ...db.cards[data._id],
                    ...activeCards[data._id],
                    _selected: selected
                }
            }
        })

    }

    const openSideModule = async (module = "cardEdit") => {
        props.reduxFunction("ASYNC", "SET_MODULE", {
            ...reduxStore.getState().sideModule,
            db: props.db,
            id: data._id,
            module: "cardEdit",
            activeModule: module,
            data: data,
        })
    }

    const setCompleted = async (ignoreStep = false) => {
        //Executa Automação
        if (data._hasStep && !ignoreStep) {
            action({
                ...props,
                nodeId: data._id,
            })
        }

        //Conclui atividade
        if (!data._hasStep || ignoreStep) {
            const countChildrens = await apims.post("/Card_Count", { id: data._id })
            if (parseInt(countChildrens.data) > 0) {
                appAlert({
                    message: translate('$__concludeWithChildrensNotConcludedConfirm', 1),
                    autoClose: 50000,
                    buttons: [
                        {
                            shadow: true,
                            title: translate("$__concludeAll"),
                            text: translate("$__concludeAll"),
                            color: "green",
                            icon: "close",
                            size: "25px",
                            onClick: () => {
                                updateCardStatus(
                                    {
                                        ...props,
                                        data: { _id: data._id }
                                    }, { status: data.status === 'completed' ? 'inProgress' : 'completed' })
                                if (countChildrens && parseInt(countChildrens.data) > 0)
                                    cardStatus(props, data._id, data.status === 'completed' ? 'inProgress' : 'completed')
                            },
                            style: { marginLeft: 15 }
                        },
                        {
                            shadow: true,
                            title: translate("$__cancel"),
                            text: translate("$__cancel"),
                            color: "red",
                            icon: "close",
                            size: "25px",
                            onClick: () => {
                                // toast.dismiss(key)
                            },
                            style: { marginLeft: 15 }
                        }],
                    variant: 'warning',
                    persist: false,
                    horizontal: 'right',
                })
            } else {
                updateCardStatus(
                    {
                        ...props,
                        data: { _id: data._id }
                    }, { status: data.status === 'completed' ? 'inProgress' : 'completed' })
                if (countChildrens && parseInt(countChildrens.data) > 0)
                    cardStatus(props, data._id, data.status === 'completed' ? 'inProgress' : 'completed')
            }
        }
    }

    const openPlan = async (id) => {
        if (
            parseInt(CardAccess) > 2
            ||
            (AppAccess.owner
                ||
                (
                    AppAccess.plan
                    && (
                        AppAccess.planAdmin
                        || AppAccess.planModerate
                    )
                )
            )
        ) {
            if (!data.planType || data.planType === "strategic" || data.planType === "flow") {
                history.push(`sp#${id}/overview`)//#CORRECT
            } else {
                history.push(`sp#${id}/board`)//#CORRECT
            }
        } else {
            openSideModule("timeline")
        }
    }

    const deleteThis = async () => {
        appAlert({
            message: translate("$__confirmDeleteThis", 1),
            variant: "warning",
            persist: false,
            horizontal: "right",
            confirm: () => {
                // deleteNode(props, { _id: data._id })
                deleteCard(props, { _id: data._id }, false)
                if (!props.directDeletion)
                    props.reduxFunction("ASYNC", "CLEAR_MODULE")
            }
        })
    }
    const restoreThis = async () => {
        appAlert({
            message: translate("$__confirmRestoreThis", 1),
            variant: "warning",
            persist: false,
            horizontal: "right",
            confirm: () => {
                restoreCard(props, { _id: data._id })
            }
        })
    }


    const cardClick = () => {
        if (data._requireApproval
            && (
                (
                    db.cards[data._id]._parent
                    && db.cards[db.cards[data._id]._parent]
                    &&
                    (
                        !db.cards[db.cards[data._id]._parent]._users[session._id]
                        || (
                            db.cards[db.cards[data._id]._parent]._users[session._id]
                            && !db.cards[db.cards[data._id]._parent]._users[session._id].approvalResponsible
                        )
                    )
                )
                &&
                (
                    data
                    && data._users
                    && Object.keys(data._users).length === 1
                    && !data._users[session._id]
                )
            )
        ) return
        if (props.disabled)
            return
        if (props.selectable) {
            selectThis()
        } else if (!props.onClick && ((!props.locked && props.showSelected && selectable) || props.selectable)) {
            selectThis()
        } else if (props.onClick && !props.selectable && !props.locked) {
            props.onClick()
        } else if (!props.locked && !props.onlyOption && data.type === 'plan') {
            openPlan(data._id)
        } else if (!props.locked) {
            openSideModule()
        }
        if (props.onOpen)
            props.onOpen()
    }

    const expanded = props.noExpanded ? false : isExpanded || (!props.notGlobalExpanded && (props.manualExpanded || props.expanded || data._expanded || props.cardsExpanded ? true : false))

    let permission = (
        (
            AppAccess
            &&
            (AppAccess.owner
                ||
                (
                    AppAccess.plan
                    && (
                        AppAccess.planAdmin
                        || AppAccess.planModerate
                    )
                )
            )
        )
        ||
        (
            CardAccess > 2
        )
    )
        ? true : false

    const openContextMenu = () => {
        // console.log('OPENNNNNN')
    }

    //MYDAY
    let myDay = { button: false, date: null }
    if (data && data._users
        && Object.keys(data._users).filter(fil => fil === session._id).length > 0
        && db.dataType.filter(c => c.name === data.type).length > 0
        && db.dataType.filter(c => c.name === data.type)[0].modules.indexOf("myDay") > -1) {
        if (data._users[Object.keys(data._users).filter(fil => fil === session._id)[0]].myDay) {
            myDay = {
                button: true,
                date: data._users[Object.keys(data._users).filter(fil => fil === session._id)[0]].myDay
            }
        } else {
            myDay = {
                button: true,
                date: null
            }
        }
    } else {
        myDay = {
            button: false,
            date: null
        }
    }
    if (data && data.private && (
        !data._users
        || (
            data._users
            && !data._users[session._id]
        )
    ))
        return (<div ref={cRef}></div>)

    if (useTemplate) {
        return (
            <UseTemplate cardId={data._id}
                toTemplate={!data.isTemplate ? true : false}
                onComplete={() => {
                    if (mounted.current)
                        setUseTemplate(false)
                }} />
        )
    }

    let locked = false

    let headerData = {
        title: data?.name || translate("$__new", 1),
        icon: data?.icon || (data.type && db && db.dataType && db.dataType.filter(a => a.name === data.type)[0] && db.dataType.filter(a => a.name === data.type)[0].icon) || "$__topic",
        iconTitle: `$__${data.type}`,
    }

    if (
        (data._planId && db.cards[data._planId]?.customColumns)
        && (data.type.indexOf("guideline") > -1 || data.type.indexOf("objective") || data.type.indexOf("goal"))
    ) {
        let columnData = JSON.parse(db.cards[data._planId].customColumns)
        // if ()
        //     headerData = {
        //         ...headerData,

        //     }
        let filType = data.type.indexOf('guideline') > -1 ? `guideline` : data.type
        if (columnData[`${filType}s`])
            headerData = {
                ...headerData,
                ...columnData[`${filType}s`].name ? { iconTitle: columnData[`${filType}s`].name } : {},
                ...columnData[`${filType}s`].icon ? { icon: columnData[`${filType}s`].icon } : {}
            }
    }

    return (
        <Container
            ref={cRef}
            separator={props.separator || false}
            plan={expanded && data.type === 'plan' ? true : false}
            isDragging={(props.snapshot && props.snapshot.isDragging) || null}
            isDragDisable={data.isDragDisable || props.isDragDisable || false}
            inLine={data.type !== "plan" && props.inLine ? true : false}
            className={!props.noShadow ? classes.container : classes.containerNoShadow}
            selectable={props.showSelected}
            selected={hashs[1] === "matrix" && reduxStore.getState().db.cards[data._id]._selected ? true : search && search.selectedCards && Object.keys(search.selectedCards).filter(a => search.selectedCards[a] === data._id).length > 0 ? true : false}
            priority={data.priority && db.priority.filter(a => String(a.value) === String(data.priority)).length > 0 ? db.priority.filter(a => String(a.value) === String(data.priority))[0].color : null}
            locked={props.locked || false}
            snapshot={props.snapshot || null}
            isCombining={props.snapshot && props.snapshot.combineTargetFor ? true : false}
            onMouseOver={() => {
                if (mounted.current)
                    setShowMoreButton(true)
            }}
            onMouseLeave={() => {
                if (mounted.current)
                    setTimeout(() => {
                        if (mounted.current)
                            setShowMoreButton(false)
                    }, 100)
            }}
            onClick={(e) => {
                !props?.showSelectBox && cardClick()
            }}
            onContextMenu={(e) => {
                openContextMenu(e)
            }}
        >
            {(data.status === 'completed' || (props.cardColorPicker && cardColor.color)) &&
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: "pointer"
                }}>
                    <div style={{
                        position: 'absolute',
                        backgroundColor: props.cardColorPicker && cardColor.color ? cardColor.color : 'rgba(0,0,0,1)',
                        opacity: cardColor.color ? 0.2 : 0.1,
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        cursor: "pointer"
                    }}>
                    </div>

                </div>
            }

            <React.Fragment>
                {props.snapshot && props.snapshot.combineTargetFor &&
                    <div style={{ backgroundColor: customApp('menu'), padding: '7px 3px', color: '#FFFFFF', fontWeight: 'bold', textAlign: 'center' }}>
                        {translate('$__dropHereToLink')}
                    </div>
                }
                <div style={{
                    position: 'absolute',
                    border: props?.selectedCard && '2px solid #4682B4',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                }}></div>
                <div style={{ display: 'flex', position: 'relative' }}>
                    {props?.showSelectBox &&
                        <input
                            type="checkbox"
                            style={{
                                width: '20px',
                                flex: 1,
                                height: '20px',
                                display: 'flex',
                                top: '5px',
                                left: '7px',
                                alignItems: 'center',
                                cursor: 'pointer',
                                borderRadius: '2px',
                                border: '1px solid #064373',
                                position: 'absolute',
                            }}
                            defaultChecked={props.selectedCard}
                            onClick={() => {
                                props.onSelect(props.cardId)
                            }}
                        />
                    }
                    <HeaderWithIcon
                        private={(data.private || data.parentPrivate) ? true : false}
                        unlockedPrivates={props.unlockedPrivates[data._id] ? true : false}
                        // unlockedPrivates={true}
                        style={{ paddingLeft: props?.showSelectBox ? '30px' : '' }}
                        setPrivate={() => {
                            props.reduxFunction("ASYNC", "SET_CONTROL", {
                                ...reduxStore.getState().controls,
                                unlockedPrivates: {
                                    ...reduxStore.getState().controls.unlockedPrivates,
                                    [data._id]: !props.unlockedPrivates[data._id]
                                }
                            })
                            if (mounted.current)
                                setShowPrivateCard(!props.unlockedPrivates[data._id])
                            if (props.setPrivate)
                                props.setPrivate(!props.unlockedPrivates[data._id])
                        }}
                        viewAllName={props.viewAllName}
                        title={headerData.title}
                        tooltipText={`${data._parent && db.cards[data._parent] ? `${db.cards[data._parent].name} / ` : ``}${headerData.title}`}
                        searchText={props?.searchText || null}
                        icon={headerData.icon}
                        iconTitle={headerData.iconTitle}
                        color={"#6d6d6d"}
                        editable={!data.name && (!props.onlyOption || props.editable) ? true : false}
                        _id={data._id}
                        locked={data.type === "link" || props.locked ? true : false}
                        colorPicker={permission && props.cardColorPicker && cardColor && !cardColor.parent ? (color) => {
                            if (
                                (!cardColor.color && color)
                                || (cardColor.color && cardColor.color !== color)
                            )
                                props.reduxFunction("ASYNC", "SET_DB", {
                                    ...db,
                                    cards: {
                                        ...db.cards,
                                        [data._id]: {
                                            ...db.cards[data._id],
                                            color
                                        },
                                    }
                                })
                        } : null}
                        onEdit={
                            (
                                data.status !== 'completed'
                                &&
                                (
                                    (
                                        CardAccess
                                        && parseInt(CardAccess) > 1
                                    )
                                    ||
                                    (
                                        AppAccess
                                        && AppAccess.plan
                                        &&
                                        (
                                            AppAccess.owner
                                            || AppAccess.planAdmin
                                            || AppAccess.planEdit
                                            || AppAccess.planModerate
                                        )
                                    )
                                )
                            ) ? (res) => {
                                props.reduxFunction("ASYNC", "SET_DB", {
                                    ...db,
                                    cards: {
                                        ...db.cards,
                                        [data._id]: {
                                            ...db.cards[data._id],
                                            name: res
                                        },
                                    }
                                })
                            } : null}
                        moreButton={showMoreButton}
                        options={

                            data.deleted ? [
                                {
                                    name: translate(`$__restoreThis`, 1),
                                    icon: 'restore_from_trash',
                                    onClick: () => {
                                        restoreThis()
                                    },
                                    show: "hover",
                                    color: customApp('medium'),
                                }
                            ]
                                : !props.onlyOption && (!props.showSelected && !props.locked) ? [
                                    Object.keys(db.cards).filter(fil => db.cards[fil]
                                        && db.cards[fil].type === "step"
                                        && db.cards[fil]._parent === data._id
                                    ).length > 0
                                    && {
                                        name: "$__openTaskList",
                                        icon: "schema",
                                        show: "fixed",
                                        onClick: () => {
                                            props.reduxFunction("ASYNC", "SET_CONTROL", {
                                                ...reduxStore.getState().control,
                                                board: data._id,
                                            })
                                        },
                                    },
                                    data.url &&
                                    {
                                        name: "$__openLinkinNewTab",
                                        icon: "launch",
                                        onClick: () => {
                                            window.open(data.url, '_blank')
                                        },
                                    },

                                    data.type !== "chatGroup"
                                    && data.type !== "step"
                                    && !data.isTemplate
                                    && !data.archived
                                    && data.status !== 'completed'
                                    // && myDay
                                    // && myDay.button
                                    && {
                                        name: moment(new Date(myDay.date * 1000)).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD") ? "$__removeOfMyDate" : "$__addOnMyDay",
                                        icon: moment(new Date(myDay.date * 1000)).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD") ? "auto_delete" : "alarm_add",
                                        color: moment(new Date(myDay.date * 1000)).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD") ? "red" : "lightblue",
                                        show: "hover",
                                        onClick: () => {
                                            let myDayOption = (!myDay || !myDay.date) || (myDay && myDay.date && moment(new Date(myDay.date * 1000)).format("YYYY/MM/DD") !== moment().format("YYYY/MM/DD")) ? true : false
                                            setMyDay(props, {
                                                _id: data._id,
                                                userId: session._id,
                                                option: myDayOption
                                            })
                                            if (props.myDayClick)
                                                props.myDayClick()
                                        },
                                    },
                                    props.suggestion && {
                                        name: "$__removeFromMyDaySuggestions",
                                        icon: "playlist_remove",
                                        show: "hover",
                                        onClick: () => {
                                            setMyDay(props, {
                                                _id: data._id,
                                                userId: session._id,
                                                option: false,
                                                noLog: true
                                            })
                                            if (props.myDayClick)
                                                props.myDayClick()
                                        },
                                    },
                                    !data.archived && !props.onlyOption && ((CardAccess && parseInt(CardAccess) > 1) || AppAccess.owner || AppAccess.planAdmin || AppAccess.planEdit || AppAccess.planModerate) &&
                                    {
                                        name: "$__edit",
                                        icon: "edit",
                                        onClick: () => {
                                            if (props.onAction)
                                                props.onAction()
                                            openSideModule()
                                        },
                                        show: "hover"
                                    },
                                    (
                                        !data.archived
                                        && !data.isTemplate
                                        && data._users
                                        && data.status !== 'completed'
                                        && data._users[session._id]
                                        &&
                                        (
                                            !data._kpiTimer
                                            ||
                                            Object.keys(data._kpiTimer).length === 0
                                            ||
                                            (
                                                data._kpiTimer
                                                && Object.keys(data._kpiTimer).length > 0
                                                && Object.keys(data._kpiTimer).filter(at =>
                                                    session
                                                    && session._id
                                                    && at
                                                    && data._kpiTimer[at]
                                                    && data._kpiTimer[at].userId
                                                    && data._kpiTimer[at].userId === session._id
                                                ).length === 0
                                            )
                                        )
                                    )
                                    &&
                                    {
                                        name: "$__timerStart",
                                        icon: "play_circle_outline",
                                        color: 'blue',
                                        show: 'hover',
                                        onClick: () => {
                                            appAlert({
                                                message: translate('$__confirmStartTimer', 1),
                                                variant: 'warning',
                                                persist: false,
                                                horizontal: 'right',
                                                confirm: () => {
                                                    kpiTimerStart(props, data._id)
                                                    let myDayOption = (!myDay || !myDay.date) || (myDay && myDay.date && moment(new Date(myDay.date * 1000)).format("YYYY/MM/DD") !== moment().format("YYYY/MM/DD")) ? true : false
                                                    if (myDayOption)
                                                        setMyDay(props, {
                                                            _id: data._id,
                                                            userId: session._id,
                                                            option: true
                                                        })
                                                    if (props.myDayClick)
                                                        props.myDayClick()
                                                }
                                            })
                                        },
                                    },
                                    // data._hasStep && {
                                    //     name: "$__completeStep",
                                    //     icon: "rule",
                                    //     color: 'green',
                                    //     show: 'hover',
                                    //     onClick: async () => {
                                    //         setCompletedStep(data._id)
                                    //     },
                                    // },

                                    //CONCLUDE OPTIONS
                                    (
                                        data._users
                                        && data.status !== 'completed'
                                        && data._users[session._id]
                                        && data._hasStep
                                    )
                                    &&
                                    {
                                        name: "$__completeStep",
                                        icon: "playlist_add_check",
                                        color: '#6ab6c2',
                                        show: 'hover',
                                        onClick: async () => {
                                            setCompleted()
                                        },
                                    },

                                    (
                                        data._users
                                        && data.status !== 'completed'
                                        && data._users[session._id]
                                    )
                                    &&
                                    {
                                        name: "$__complete",
                                        icon: "check_circle",
                                        color: 'green',
                                        show: 'hover',
                                        onClick: async () => {
                                            setCompleted(true)
                                        },
                                    },

                                    (
                                        !data.archived
                                        && !props.onlyOption
                                        && (
                                            props.selectOption
                                            || props.viewButton
                                        )
                                    ) && {
                                        name: data._selected || (
                                            props.searchs
                                            && props.searchs[searchID]
                                            && props.searchs[searchID].selectedCards
                                            && Object.keys(props.searchs[searchID].selectedCards).filter(tp => props.searchs[searchID].selectedCards[tp] === data._id).length > 0
                                        ) ? "$__removeSelection" : "$__selectThis",
                                        icon: data._selected || (
                                            props.searchs
                                            && props.searchs[searchID]
                                            && props.searchs[searchID].selectedCards
                                            && Object.keys(props.searchs[searchID].selectedCards).filter(tp => props.searchs[searchID].selectedCards[tp] === data._id).length > 0
                                        ) ? "visibility_off" : "visibility",
                                        hide: true,
                                        onClick: () => {
                                            selectThis()
                                        },
                                    },
                                    {
                                        name: "$__duplicate",
                                        icon: "content_copy",
                                        onClick: () => {
                                            props.reduxFunction("ASYNC", "SET_CONTROL", {
                                                ...reduxStore.getState().control,
                                                toCopyMove: data._id,
                                            })
                                        },
                                    },
                                    // {
                                    //     name: "$__move",
                                    //     icon: "drive_file_move",
                                    //     onClick: () => {
                                    //         setMove(true)
                                    //         setCopyName(data.name)
                                    //     },
                                    // },
                                    !data.isTemplate && data.type === 'plan' &&
                                    {
                                        name: "$__copyToTemplates",
                                        icon: "copy_all",
                                        onClick: () => {
                                            if (mounted.current)
                                                setUseTemplate(true)
                                        },
                                    },
                                    (
                                        data
                                        && !data.isTemplate
                                        && data.type === 'plan'
                                        && !data.archived
                                        && (
                                            (
                                                data._users
                                                && data._users[session._id]
                                                && data._users[session._id].access
                                                && parseInt(data._users[session._id].access) === 5
                                            )
                                            ||
                                            (
                                                AppAccess
                                                && AppAccess.planAdmin
                                            )
                                        )
                                    ) && {
                                        name: translate('$__shelve'),
                                        icon: 'archive',
                                        onClick: () => {
                                            updateCard(props, {
                                                _id: data._id,
                                                archived: true,
                                            })
                                        },
                                    },
                                    ((CardAccess && parseInt(CardAccess) === 5) || AppAccess.owner || AppAccess.planAdmin || AppAccess.planEdit) && !props.disableDelete &&
                                    {
                                        name: "$__delete",
                                        icon: "delete_forever",
                                        color: 'red',
                                        onClick: () => {
                                            if (
                                                props.directDeletion
                                                || data.archived
                                                || (
                                                    db.dataType.filter(c => c.name === data.type).length === 0
                                                    ||
                                                    (
                                                        db.dataType.filter(c => c.name === data.type).length > 0
                                                        && db.dataType.filter(c => c.name === data.type)[0].module === 'swot'
                                                    )
                                                )
                                            ) {
                                                deleteThis()
                                            } else {
                                                openSideModule('delete')
                                            }
                                            if (props.onAction)
                                                props.onAction()
                                        },
                                        show: "hover"
                                    },
                                    (
                                        data
                                        && !data.isTemplate
                                        && data.type === 'plan'
                                        && data.archived
                                        && (
                                            (
                                                data._users
                                                && data._users[session._id]
                                                && data._users[session._id].access
                                                && parseInt(data._users[session._id] === 5)
                                            )
                                            ||
                                            (
                                                AppAccess && AppAccess.planAdmin
                                            )
                                        )
                                    ) && {
                                        name: translate('$__unarchive'),
                                        icon: 'archive',
                                        onClick: () => {
                                            updateCard(props, {
                                                _id: data._id,
                                                archived: null,
                                            })
                                        },
                                    },
                                    !data.archived
                                    && !data.isTemplate
                                    && {
                                        name: !data._isFavorite ? "$__addToFavorite" : "$__removeFromFavorite",
                                        icon: !data._isFavorite ? "favorite_border" : "favorite",
                                        color: customApp('medium'),
                                        onClick: async () => {
                                            favoriteNode(props, data._id, !data._isFavorite ? false : true)
                                            props.reduxFunction("ASYNC", "SET_DB", {
                                                ...db,
                                                cards: {
                                                    ...db.cards,
                                                    [data._id]: {
                                                        ...db.cards[data._id],
                                                        _isFavorite: !data._isFavorite ? true : false
                                                    },
                                                }
                                            })
                                        },
                                    },
                                    !data.isTemplate
                                    && !data.archived
                                    && (props.my || props.showCompleteOption) && {
                                        name: data.status !== "$__completed" ? "$__markasdone" : "$__concluded",
                                        icon: "check_circle_outline",
                                        color: data.status !== "completed" ? "lightgray" : "green",
                                        onClick: async () => {
                                            setCompleted()
                                        },
                                    }
                                ] : []}
                        isExpanded={props.noExpanded ? false : expanded}
                        noExpanding={props.expanded || db.cardsExpanded || false}
                        onExpanding={() => {
                            if (props.onExpand) {
                                props.onExpand(!expanded)
                            }
                            if (!props.expanded && !db.cardsExpanded) {
                                if (mounted.current)
                                    setExpanded((prevExpanded) => !prevExpanded)
                            }
                        }
                        }
                        onClick={(e) => {
                            // e.preventDefault()
                            // cardClick()
                        }}
                    />
                </div>
                {data && (data._cardCode || data.value || data.ref1 || data.ref2) ?
                    <div style={{
                        width: "100%",
                        clear: "both",
                        marginLeft: props?.showSelectBox ? '30px' : '',
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "space-between"
                    }}>
                        {data && data._cardCode && (
                            !expanded
                            || (
                                expanded &&
                                !data.image
                            )
                        )
                            ?
                            <div
                                style={{
                                    paddingLeft: 36,
                                    fontSize: 11,
                                    marginTop: -9,
                                    color: "gray",
                                    display: "flex",
                                    alignItems: "center",
                                    whiteSpace: "nowrap"
                                }}>
                                # {data._cardCode}
                            </div>
                            : <React.Fragment></React.Fragment>
                        }
                        <div style={{ display: "flex" }}>
                            {data && (data.ref1 || data.ref2) ?
                                <div
                                    style={{
                                        textAlign: "right",
                                        fontSize: 11,
                                        marginTop: -9,
                                        color: "blue",
                                    }}>
                                    {`${data.ref1} `}
                                </div>
                                : <React.Fragment></React.Fragment>
                            }
                            {data && data.ref2 ?
                                <div
                                    style={{
                                        textAlign: "right",
                                        fontSize: 11,
                                        marginTop: -9,
                                        color: "green",
                                    }}>
                                    | {data.ref2}
                                </div>
                                : <React.Fragment></React.Fragment>
                            }
                        </div>
                        {data && data.value ?
                            <div
                                style={{
                                    width: "100%",
                                    textAlign: "right",
                                    fontSize: 11,
                                    marginTop: -9,
                                    color: "black",
                                }}>
                                $ {data.value}
                            </div>
                            : <React.Fragment></React.Fragment>
                        }
                    </div>
                    : <React.Fragment></React.Fragment>
                }
                {(((data.type === 'insight' || data.type === 'link') && data._createdBy && db.users[data._createdBy._id])
                    ||
                    (data._createdBy && data._createdBy.channel && data._createdBy._id && db.users && db.users[data._createdBy._id])
                ) ?
                    <div style={{
                        width: 'calc(100% - 0px)',
                        alignItems: 'center',
                        padding: '7px',
                        display: 'inline-flex',
                        borderBottom: 'solid 1px rgba(0,0,0,0.03)'
                    }}>
                        <div style={{
                            position: 'relative',
                            float: 'left'
                        }}>
                            <Tooltip
                                title={data._createdBy.name || db.users[data._createdBy._id].name}
                            >
                                <Avatar
                                    alt={data._createdBy.name || db.users[data._createdBy._id].name}
                                    src={db.users[data._createdBy._id]?.image || null}
                                    size="12px"
                                    style={{ width: 16, height: 16 }}
                                >
                                    {data._createdBy?.name?.substr(0, 1) || db.users[data._createdBy._id].name.substr(0, 1)}
                                </Avatar>
                            </Tooltip>
                        </div>
                        <div style={{
                            position: 'relative',
                            float: 'right',
                            width: '100%',
                            paddingLeft: '7px',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            fontSize: '12px'
                        }}>
                            <div style={{
                                color: customApp('menu'),
                                fontWeight: 'bold',
                            }}>
                                <span style={{ fontSize: 10, color: "gray" }}>{translate("$__createdBy", '*')}</span>: {data._createdBy?.name?.split(' ')[0] || db.users[data._createdBy._id].name.split(' ')[0]}
                            </div>
                            <div>
                                {moment(new Date()).tz("America/Sao_Paulo").format("DD/MM/YYYY") === moment(data.created_at * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY") ?
                                    `${moment(data.created_at * 1000).tz("America/Sao_Paulo").format("H:mm")}`
                                    :
                                    moment(new Date()).tz("America/Sao_Paulo").format("YYYY") === moment(data.created_at * 1000).tz("America/Sao_Paulo").format("YYYY") ?
                                        `${moment(data.created_at * 1000).tz("America/Sao_Paulo").format("DD/MM H:mm")}`
                                        :
                                        `${moment(data.created_at * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY H:mm")}`
                                }
                            </div>
                        </div>
                    </div>
                    : <></>}

                {data._kpiTimer && Object.keys(data._kpiTimer).length > 0 && Object.keys(data._kpiTimer).filter(a => data._kpiTimer[a].userId === session._id).length !== 0 &&
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            float: 'left'
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    >
                        <Timer data={data} />
                    </div>
                }
                {(
                    !props.noExpanded
                    && expanded
                    && (
                        !data.private
                        || (
                            data.private
                            && (showPrivateCard || reduxStore.getState().controls.lockPrivate)
                        )
                    )
                ) ?
                    <React.Fragment>
                        <CardExpanded
                            {...props}
                            private={data.private ? true : false}
                            expanded
                            Resume={true}
                            onResize={() => {
                                // onResize()
                            }}
                        />
                        <div>
                            {props.searchText && data.description &&
                                <div className={classes.description}>
                                    {Highlighted(data.description, props.searchText)}
                                </div>
                            }
                        </div>
                    </React.Fragment>
                    : expanded ? <React.Fragment>
                        <FakeExpanded />
                    </React.Fragment>
                        : <React.Fragment></React.Fragment>
                }
                {!data.isTemplate ?
                    <div>
                        {
                            (
                                data.status !== "completed"
                                &&
                                (
                                    (
                                        data.startDate
                                        && moment(data.startDate).isValid()
                                    )
                                    ||
                                    (
                                        data.endDate
                                        && moment(data.endDate).isValid()
                                    )
                                )
                            )
                                ?
                                <div>
                                    <div className={
                                        (
                                            data.endDate
                                            && moment(new Date(data.endDate * 1000)).tz("America/Sao_Paulo").format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
                                            && data.status !== "completed"
                                        )
                                            ? classes.lateDates
                                            : classes.dates
                                    }>
                                        {data.startDate && moment(data.startDate).isValid() &&
                                            <div className={classes.dateInfo}>
                                                <Badge
                                                    overlap="rectangular"
                                                    className={classes.dateIco} icon="insert_invitation" title={"$__startDate"} size="14px" disabled />
                                                {(new Date(data.startDate * 1000) instanceof Date && !isNaN(new Date(data.startDate * 1000))) ?
                                                    moment(new Date(data.startDate * 1000)).tz("America/Sao_Paulo").format("DD/MM/YYYY")
                                                    :
                                                    moment(data.startDate).tz("America/Sao_Paulo").format("DD/MM/YYYY")
                                                }
                                            </div>
                                        }
                                        {data.endDate && moment(data.endDate).isValid() ?
                                            <div className={classes.dateInfo}>
                                                <Badge
                                                    overlap="rectangular"
                                                    className={classes.dateIco} icon="event_available" title={"$__endDate"} size="14px" disabled />
                                                {(new Date(data.endDate * 1000) instanceof Date && !isNaN(new Date(data.endDate * 1000))) ?
                                                    moment(new Date(data.endDate * 1000)).tz("America/Sao_Paulo").format("DD/MM/YYYY")
                                                    :
                                                    moment(data.endDate).tz("America/Sao_Paulo").format("DD/MM/YYYY")
                                                }
                                            </div>
                                            : <React.Fragment></React.Fragment>
                                        }
                                    </div>
                                </div>
                                : <React.Fragment></React.Fragment>

                        }

                    </div>
                    : <React.Fragment></React.Fragment>
                }
                {data._tags && data._tags.filter(a => a.value).length > 0 ?
                    <div
                        style={props.showTags || expanded ? {
                            marginBottom: 33
                        } : {}}
                    >
                        <CardTags
                            options={data._tags}
                            expanded={props.showTags || (
                                expanded
                                && data.type !== 'plan'
                                && data.type !== 'link'
                                && data.type !== 'insight'
                            )
                                ? true
                                : false
                            }
                        />
                    </div> : <React.Fragment></React.Fragment>
                }
                {
                    data._recurrenceId &&
                    <div
                        style={{
                            position: 'absolute',
                            width: "100%",
                            bottom: '3px',
                            left: '3px',
                            display: 'flex',
                            fontSize: '11px',
                            color: 'lightgrey',
                            alignItems: 'center'
                        }}
                    >
                        <Badge
                            overlap="rectangular"
                            className={classes.indicatorsIcon}
                            icon={'history'}
                            color={'lightgrey'}
                            size={"25px"}
                            title={!props.noShowRecurrenceCard ? (
                                <div style={{ maxWidth: "280px" }}>
                                    {translate('$__recurrentActivityFrom')}:
                                    <Card db="cards" cardId={data._recurrenceId} Avatar ignoreList onlyOption />
                                </div>
                            ) : `${translate('$__recurrence')}${data.recurrenceCreatedDate ? `: ${moment(data.recurrenceCreatedDate).format("DD/MM/YYYY")}` : ``}`}
                        />
                        {data.recurrenceCreatedDate ? moment(data.recurrenceCreatedDate).format("DD/MM/YYYY") : ``}
                    </div>

                }
                {(
                    expanded
                    || (
                        data._users
                        && Object.keys(data._users).filter(a => String(a) !== null).length > 0
                        &&
                        (
                            !data._users[session._id]
                            ||
                            (
                                data._users[session._id]
                                && Object.keys(data._users).length > 1
                            )
                        )
                    )
                )
                    ?
                    <React.Fragment>
                        <div style={{
                            width: "100%",
                            marginTop: expanded ? 37 : 7
                        }}>
                            <div className={classes.usersContent}>
                                <div className={classes.users}>
                                    <QuickUsersList _id={data._id} data={data} permission={permission} transparent myDay limit={3} />
                                </div>
                            </div>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </React.Fragment>
                    : <React.Fragment></React.Fragment>
                }
            </React.Fragment>
            {
                data.isTemplate &&
                <div style={{
                    position: "relative",
                    width: "100%",
                    clear: "both",
                    padding: "7px",
                    backgroundColor: customApp("menu"),
                    marginTop: "7px",
                    cursor: "pointer"
                }}
                    onClick={(e) => {
                        e.stopPropagation()
                        if (mounted.current)
                            setUseTemplate(true)
                    }}>
                    <IconButton
                        text={translate("$__useThisTemplate", '*')}
                        icon="auto_awesome"
                        iconColor={'#FFFFFF'}
                        textColor={'#FFFFFF'}
                    />
                </div>
            }
            {
                data._requireApproval &&
                    (
                        data._parent
                        && (
                            !db.cards[data._parent]
                            || (
                                db.cards[data._parent]
                                &&
                                (
                                    (
                                        db.cards[data._parent]._users
                                        && (
                                            !db.cards[data._parent]._users[session._id]
                                            || (
                                                db.cards[data._parent]._users[session._id]
                                                && !db.cards[data._parent]._users[session._id].approvalResponsible
                                            )
                                        )
                                    )

                                )
                            )
                        )
                        ||
                        (
                            db.cards[data._id]._users
                            && (
                                Object.keys(db.cards[data._id]._users).length === 1
                                && db.cards[data._id]._users[session._id]
                            )
                        )
                    ) ?
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        cursor: "pointer"
                    }}>
                        <div style={{
                            position: 'absolute',
                            backgroundColor: 'rgba(0,0,0,0.6)',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: "center",
                            cursor: "pointer",
                            color: "white"
                        }}>
                            <Icon icon="lock" color="white" />
                            <span>{translate("$__awaitApproval")}</span>
                        </div>
                    </div>
                    : !showMoreButton && data.status === 'completed' &&
                    <div style={{
                        backgroundColor: 'rgba(0,0,0,0.4)',
                        position: 'absolute',
                        height: '2px',
                        left: 0,
                        right: 0,
                        top: '20px'
                    }}>
                        <div
                            style={{
                                position: "absolute",
                                right: 0,
                                bottom: -12,
                                color: "#FFF",
                                fontSize: 10,
                                padding: 3,
                                backgroundColor: "rgba(0,0,0,0.66)",
                            }}
                        >
                            {translate("$__completeDate")}:
                            {getDate(data.completed_at || data.updated_at)}
                        </div>
                    </div>
            }
            {
                (data.type !== 'plan' && data.deleted) ?
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        cursor: "pointer"
                    }}>
                        <div style={{
                            position: 'absolute',
                            backgroundColor: '#71389da6',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            paddingRight: 7,
                            display: 'flex',
                            alignItems: 'center',
                            cursor: "pointer",
                            justifyContent: "right"
                        }}>
                            <CustomButton
                                title={translate(`$__restoreThis`, 1)}
                                text={translate(`$__restoreThis`, 1)}
                                color="#FFFFFF"
                                icon={"restore_from_trash"}
                                onClick={() => {
                                    restoreThis()
                                }}
                                size={"17px"}
                                textSize={"17px"}
                                transparent
                            />
                        </div>

                    </div>
                    : <React.Fragment></React.Fragment>
            }
            {
                locked ?
                    <div style={{
                        position: "absolute",
                        backgroundColor: "rgba(233,233,233,0.88)",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <div style={{
                            display: "flex"
                        }}>
                            <Icon color="lightgray" icon="lock" size={"22px"} />
                        </div>

                    </div>
                    :
                    <React.Fragment></React.Fragment>
            }
            <div style={{ clear: "both" }}></div>
        </Container >
    )
}

const useStyles = makeStyles(styles)

const Container = styled.div`
            border-radius:5px;
            min-width:200px;
            background-color:#fff;
            margin-bottom:7px;

            ${props =>
        props.separator ? `marging-top:30px;` : ``
    }
            ${props =>
        props.selectable && props.selected ? `
        opacity:1;`
            : props.selectable ? `
        opacity:0.5;`
                : ``
    }
            ${props =>
        props.locked ?
            `cursor:no-drop !important;`
            : `cursor:pointer;`
    }
            ${props =>
        props.selected ?
            `border-bottom:solid 5px ${customApp("color")} !important;`
            : ``
    }
            ${props =>
        props.isDragDisable
            ? ""
            : props.isDragging
                ? `opacity:0.88;
                 max-width:320px;
                 border:solid 10px red;`
                : ``
    }
            ${props =>
        props.inLine ?
            `position:relative;
            float:left;
            margin:3.5px;
            width:calc(20% - 7px);
            // maxWidth:320px;
            `
            : `
            width:calc(100%) !important;
            margin:7px 0px 12px 0px;
            `
    }
            width:calc(100%) !important;

            ${props =>
        props.priority ?
            `border-left:5px solid ${props.priority} !important;`
            : ``
    }
            ${props => props.isCombining ? `border:solid 3px ${customApp('menu')} !important;` : ``}
            `


//REACT
const mapStateToProps = (store) => ({
    cardsExpanded: store.db.cardsExpanded,
    showPrivate: store.controls.lockPrivate,
    cards: store.db.cards,
    unlockedPrivates: store.controls.unlockedPrivates,
    controls: store.controls,
    searchs: store.searchs
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Card)