const initialState = {
    sideModuleWindow: false,
    period: '$__last7Days',
    users: {},
    showDeleted: false,
    hidePrivate: false,
    lockPrivate: false,
    unlockedPrivates: 0,
    board: null,
    toCopyMove: null,
    selectedCards: null,
    addCard: {},
    importUsers: false,
    agileOrderBy: "name",
    agileOrderDesc: "DESC",
};

const session = (state = initialState, data) => {
    if (data.action === 'SET_CONTROL' && data.value) {
        return {
            ...state,
            ...data.value,
        }
    } else if (data.action === 'RESET_CONTROLS') {
        return {
            ...state,
        }

    } else {
        return state
    }
};

export default session;