import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import MD5 from "crypto-js/md5";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store";

//COMPONENTS
import HeaderWithIcon from "components/Header/card"
import CustomButton from "components/Buttons/custom"
import Card from "components/Card";
import Icon from "components/Icon";
import SelectedMenu from "componentsV3/SelectedMenu/SelectedMenu";

//@MATERIAL
import Dialog from "@material-ui/core/Dialog";
import Button from '@material-ui/core/Button';

//DEPENDENCIES
import Column from "./Column";

//FUNCTIONS
import { linkCard, updateCard, getChildrens } from "functions/cards";
import { translate, appAlert, customApp } from "functions";
import { countNotifications } from "functions/cards"



function App(props) {
    const { db, session } = reduxStore.getState()
    const { dataType } = db
    const [Migrator, setMigrator] = React.useState(false)
    const [cardsUpdate, setCardsUpdate] = React.useState(true)
    const [MigratorData, setMigratorData] = React.useState({})
    const [selectedCardState, setSelectedCardState] = React.useState({
        checkSelectedCards: [],
        childrenChecked: []
    })
    const [cards, setCards] = useState({})


    const searchID = MD5(`${window.location.pathname}-${window.location.hash}`).toString()

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')
    if (hashs[0].length !== 36) {
        hashs[0] = null
    }

    let activeCards = {}
    let selectedTree = []

    const selectedGuideline = props.searchs[searchID]?.selectedCards?.guideline || null
    const selectedObjective = props.searchs[searchID]?.selectedCards?.objective || null
    const selectedGoal = props.searchs[searchID]?.selectedCards?.goal || null
    const selectedTasks = props.searchs[searchID]?.selectedCards?.action || null
    const overview = [
        { value: 'guidelines', type: 'guideline', label: '$__guidelines', icon: 'alt_route', viewButton: true, selectedParent: null },
        { value: 'objectives', type: 'objective', label: '$__objectives', icon: dataType.filter(a => a.name === 'objective')[0].icon, viewButton: true, selectedParent: selectedGuideline },
        { value: 'goals', type: 'goal', label: '$__goals', icon: dataType.filter(a => a.name === 'goal')[0].icon, viewButton: true, selectedParent: selectedObjective || selectedGuideline },
        { value: 'actions', type: 'iniciative', label: '$__actions', icon: 'double_arrow', viewButton: true, selectedParent: selectedGoal || selectedObjective || selectedGuideline }
    ]



    const trackingTreeChildrens = (id) => {
        if (selectedTree.indexOf(id) === -1)
            selectedTree.push(id)
        Object.keys(reduxStore.getState().db.cards).filter(c =>
            (
                reduxStore.getState().db.cards[c]._parent
                && !reduxStore.getState().db.cards[c].checklist
                && reduxStore.getState().db.cards[c]._parent === id
            )
            && selectedTree.indexOf(c) === -1
        ).forEach(c => {
            trackingTreeChildrens(c)
            selectedTree.push(c)
        })
    }
    const trackingTreeParents = (id) => {
        if (selectedTree.indexOf(selectedTree) === -1)
            selectedTree.push(id)
        Object.keys(reduxStore.getState().db.cards).filter(c =>
            id
            && reduxStore.getState().db.cards[id]
            &&
            (
                (
                    c === id
                    || reduxStore.getState().db.cards[id]?._parent === c
                )
                && !reduxStore.getState().db.cards[c].checklist
            )
            && selectedTree.indexOf(c) === -1
        ).forEach(c => {
            if (reduxStore.getState().db.cards[c] && reduxStore.getState().db.cards[c]._parent) {
                trackingTreeParents(reduxStore.getState().db.cards[c]._parent)
            }
            selectedTree.push(c)
        })
    }

    useEffect(() => {
        if (!props.showCheck) {
            setSelectedCardState({
                checkSelectedCards: [],
                childrenChecked: []
            })
        }
    }, [props.showCheck]);

    useEffect(() => {
        if (cardsUpdate) {
            Object.keys(db.cards).filter(a => {
                let notifications = countNotifications(a)
                if (db.cards[a]
                    && (
                        !db.cards[a].private
                        || (
                            db.cards[a].private
                            && db.cards[a]._users
                            && db.cards[a]._users[session._id]
                        )
                    )
                    && (
                        db.cards[a]._planId === hashs[0]
                        || db.cards[a]._parent === hashs[0]
                    )
                    && (
                        props.showCompleted
                        ||
                        (
                            !props.showCompleted
                            && db.cards[a].status !== "completed"
                        )
                    )
                    && (
                        !props.onlyUnReaded
                        || (
                            props.onlyUnReaded
                            && notifications.total > 0
                        )
                    ))
                    return true
                return false

            }).forEach(a => {
                activeCards = {
                    ...activeCards,
                    [a]: db.cards[a]
                }
            })

            if (selectedTasks) {
                trackingTreeChildrens(selectedTasks)
                trackingTreeParents(selectedTasks)
            } else if (selectedGoal) {
                trackingTreeChildrens(selectedGoal)
                trackingTreeParents(selectedGoal)
            } else if (selectedObjective) {
                trackingTreeChildrens(selectedObjective)
                trackingTreeParents(selectedObjective)
            } else if (selectedGuideline) {
                trackingTreeChildrens(selectedGuideline)
                trackingTreeParents(selectedGuideline)
            }
            let cards = {}

            cards['guidelines'] = Object.keys(activeCards).filter(a =>
                (
                    activeCards[a]._parent === hashs[0]
                    || activeCards[a]._planId === hashs[0]
                )
                && activeCards[a].type
                && activeCards[a].type.indexOf('guideline') > -1
                && (
                    selectedTree.length === 0
                    || (
                        selectedTree.length > 0
                        && selectedTree.indexOf(a) > -1
                    )
                )
            ) || null


            cards['objectives'] = Object.keys(activeCards).filter(a =>
                activeCards[a].type
                && activeCards[a].type === 'objective'
                && (
                    selectedTree.length === 0
                    || (
                        selectedTree.length > 0
                        && selectedTree.indexOf(a) > -1
                    )
                )
            ) || null

            cards['objectives'] = Object.keys(activeCards).filter(a =>
                activeCards[a].type === 'objective'
                && !activeCards[a].checklist
                && (
                    selectedTree.length === 0
                    || (
                        selectedTree.length > 0
                        && selectedTree.indexOf(a) > -1
                    )
                )
            )
            cards['objectives2'] = Object.keys(activeCards).filter(a => {
                if (!selectedObjective && activeCards[a].type === 'objective'
                    && activeCards[a]._parent !== selectedGuideline
                    && !activeCards[a].checklist
                    && cards['objectives'].indexOf(activeCards[a]._parent) > -1
                    && (
                        selectedTree.length === 0
                        || (
                            selectedTree.length > 0
                            && selectedTree.indexOf(a) > -1
                        )
                    )
                )
                    return true
                return false
            }
            )

            cards['objectives2'].forEach(a => {
                if (cards['objectives'].indexOf(a) === -1)
                    cards['objectives'].push(a)
            })

            cards['goals'] = Object.keys(activeCards).filter(a =>
                activeCards[a].type
                && activeCards[a].type === 'goal'
                && (
                    selectedTree.length === 0
                    || (
                        selectedTree.length > 0
                        && selectedTree.indexOf(a) > -1
                    )
                )
            ) || null

            cards['goals2'] = Object.keys(activeCards).filter(a =>
                activeCards[a].type === 'goal'
                && !activeCards[a].checklist
                && cards['goals'].indexOf(activeCards[a]._parent) > -1
                && (
                    selectedTree.length === 0
                    || (
                        selectedTree.length > 0
                        && selectedTree.indexOf(a) > -1
                    )
                )
            )
            cards['goals2'].forEach(a => {
                if (cards['goals'].indexOf(a) === -1)
                    cards['goals'].push(a)
            })

            cards['actions'] = Object.keys(activeCards).filter(a => (
                activeCards[a].type
                && !activeCards[a].checklist
                && activeCards[a].type !== 'plan'
                && activeCards[a].type !== 'objective'
                && activeCards[a].type !== 'goal'
                && activeCards[a].type !== 'feed'
                && activeCards[a].type !== 'link'
                && activeCards[a].type !== 'insight'
                && activeCards[a].type !== 'link'
                && activeCards[a].type !== 'chatGroup'
                && activeCards[a].type.indexOf('guideline') === -1
                && dataType.filter(dt => dt.name === activeCards[a].type).length > 0
                && dataType.filter(dt => dt.name === activeCards[a].type)[0].module !== 'swot')
                && (
                    selectedTree.length === 0
                    || (
                        selectedTree.length > 0
                        && selectedTree.indexOf(a) > -1
                    )
                )
            ) || null


            cards['guidelines'] = cards['guidelines'].map(a => activeCards[a])
            cards['objectives'] = cards['objectives'].map(a => activeCards[a])
            cards['goals'] = cards['goals'].map(a => activeCards[a])
            cards['actions'] = cards['actions'].map(a => activeCards[a])

            setCards(cards)
        }

    }, [db.cards, props.cards, props.searchs]);



    const onDragEnd = async result => {
        const { destination, source, draggableId } = result
        if (result.combine) {
            appAlert({
                message: `${translate("$__confirmLink", 1)}: ${reduxStore.getState().db.cards[draggableId].name} >>> ${reduxStore.getState().db.cards[result.combine.draggableId] ? reduxStore.getState().db.cards[result.combine.draggableId].name : ""}`,
                variant: "warning",
                persist: true,
                horizontal: "right",
                confirm: () => {
                    if (result.combine.draggableId)
                        linkCard(props, {
                            cardId: draggableId,
                            cardToLink: result.combine.draggableId
                        })
                }
            })

            return;
        }

        if (!destination) return false



        if (destination.droppableId === source.droppableId && destination.id === source.index) return false

        const sourceColumn = source.droppableId
        const destinationColumn = destination.droppableId

        const sourceCardIds = Array.from(Object.keys(activeCards).filter(c => activeCards[c].status === sourceColumn).sort((a, b) => {
            if (activeCards[a].order && activeCards[b].order && String(activeCards[a].order.low) && String(activeCards[b].order.low) && parseInt(activeCards[a].order.low) < parseInt(activeCards[b].order.low))
                return -1
            return 1
        }
        ).map(a => a._id))

        //When move Card in single Column

        if (sourceColumn === destinationColumn && reduxStore.getState().db.orderByTitle) {
            return
        }

        if (sourceColumn === destinationColumn && !reduxStore.getState().db.orderByTitle) {
            const { socket } = reduxStore.getState().functions

            let columnName = sourceColumn === 'iniciative' ? 'action' : sourceColumn

            let itemOrderIds = Array.from(
                cards[`${columnName}s`]
                    .filter(card => card.status && card.status !== "completed")
                    .sort((a, b) => {
                        let orderA = a?.order?.low || !isNaN(a?.order) ? parseInt(a.order) : a.order ? parseInt(a.order) : 0
                        let orderB = b?.order?.low || !isNaN(b?.order) ? parseInt(b.order) : b.order ? parseInt(b.order) : 0

                        if (orderA < orderB)
                            return -1
                        if (orderA > orderB)
                            return 1
                        return 0
                    })
                    .map(card => card._id)
            )

            itemOrderIds.splice(source.index, 1)
            await setTimeout(500)

            itemOrderIds.splice(destination.index, 0, draggableId)
            await setTimeout(500)


            socket.emit("data", {
                module: "cards",
                method: "put",
                action: "reorder"
            }, {
                cards: itemOrderIds
            })

            let newCards = {
                ...reduxStore.getState().db.cards
            }

            let reorderAwait = await new Promise((resolve, reject) => {
                itemOrderIds.forEach((a, i) => {
                    newCards = {
                        ...newCards,
                        [a]: {
                            ...newCards[a],
                            order: `${i + 1}`
                        }
                    }
                    if (i + 1 === itemOrderIds.length)
                        resolve(true)
                })
            })
            if (reorderAwait)
                props.reduxFunction("IMMEDIATE", "SET_DB", {
                    ...reduxStore.getState().db,
                    cards: {
                        ...reduxStore.getState().db.cards,
                        ...newCards
                    }
                })
            return
        }

        //When move Card in mult Column
        sourceCardIds.splice(source.index, 1)

        const destinationCardIds = Array.from(Object.keys(activeCards).filter(c => c.status === destinationColumn).sort((a, b) => {
            if (activeCards[a].order && activeCards[b].order && String(activeCards[a].order.low) && String(activeCards[b].order.low) && parseInt(activeCards[a].order.low) < parseInt(activeCards[b].order.low))
                return -1
            return 1
        }
        ).map(a => a._id))
        destinationCardIds.splice(destination.index, 0, draggableId)

        sourceCardIds.forEach((a, i) => {
            if (!db.cards[a].order || !db.cards[a].order.low) {
                db.cards[a] = {
                    ...db.cards[a],
                    order: {
                        low: parseInt(i)
                    }
                }
            } else {
                db.cards[a].order.low = parseInt(i)
            }
        })

        destinationCardIds.forEach((a, i) => {
            if (!db.cards[a].order || !db.cards[a].order.low) {
                db.cards[a] = {
                    ...db.cards[a],
                    order: {
                        low: parseInt(i)
                    }
                }
            } else {
                db.cards[a].order.low = parseInt(i)
            }
        })

        let childrens = getChildrens(draggableId)
        let data = {
            _id: draggableId,
            type: destination.droppableId,
            _childrens: childrens,
            _objectives: cards['objectives'].filter(fil => childrens[fil._id]).map(a => a),
            _goals: cards['goals'].filter(fil => childrens[fil._id]).map(a => a)
        }

        if (db.cards[draggableId].type === "goal" && destination.droppableId === "guideline") {
            appAlert({
                message: translate('$__YouCannotMoveThisItemToThisColumnMoveFirstToTheObjectiveColumn', 1),
                variant: 'error',
                persist: false,
                horizontal: 'center',
            })
            setMigratorData({})
            return
        }
        if (
            db.cards[draggableId].type.indexOf("guideline") === -1
            && db.cards[draggableId].type !== "objective"
            && db.cards[draggableId].type !== "goal"
            && (
                destination.droppableId === "guideline"
                || destination.droppableId === "objective"
            )
        ) {
            appAlert({
                message: translate('$__YouCannotMoveThisItemToThisColumnMoveFirstToTheGoalsColumn', 1),
                variant: 'error',
                persist: false,
                horizontal: 'center',
            })
            setMigratorData({})
            return
        }


        if (db.cards[draggableId].type.indexOf('guideline') > -1 && db.cards[draggableId].type !== 'guideline') {
            appAlert({
                message: translate('$__youCantMoveGuidelineWithSwotLinked', 1),
                variant: 'error',
                persist: false,
                horizontal: 'center',
            })
        } else if (
            db.cards[draggableId].type === 'guideline'
            &&
            (
                destination.droppableId !== 'guideline'
            )
            && (
                cards['objectives'].filter(fil => fil._parent === draggableId).length > 0
                || cards['goals'].filter(fil => fil._parent === draggableId).length > 0
            )
        ) {
            setMigratorData(data)
            setMigrator(true)
        } else if (
            db.cards[draggableId].type.indexOf('objective') > -1
            &&
            (
                destination.droppableId !== 'guideline'
                && destination.droppableId !== 'objective'
            )
            && (
                cards['goals'].filter(fil => fil._parent === draggableId).length > 0
            )
        ) {
            setMigratorData(data)
            setMigrator(true)
        } else {
            appAlert({
                message: `${translate("$__confirmTransformIn", 1)}: ${translate(`$__${destination.droppableId}`)}`,
                variant: "warning",
                persist: true,
                horizontal: "right",
                confirm: () => {
                    updateType(data)
                    if (db.cards[db.cards[draggableId]._parent].type.indexOf(destination.droppableId) > -1)
                        linkCard(props, {
                            cardId: draggableId,
                            cardToLink: db.cards[db.cards[draggableId]._parent]._parent
                        })
                },
                cancel: () => {
                    setMigratorData({})
                }
            })

        }
    }

    const updateType = async (d) => {
        let data = d
        if (MigratorData && MigratorData._id)
            data = MigratorData

        await updateCard(props, data)
        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                [data._id]: {
                    ...reduxStore.getState().db.cards[data._id],
                    type: data.type,
                },
            }
        });

        setMigratorData({})
    }

    const iniMigration = async (type) => {
        let MCards = type === 'goals' ? MigratorData._goals : MigratorData._objectives
        cards[type]
            .filter(fil => MigratorData._childrens[fil._id]).forEach(async (a, i) => {
                MCards.filter(c => c._id === a._id).forEach(a => {
                    a.type = type === 'goals' ? 'task' : 'goal'
                })

                await updateCard(props, {
                    _id: a._id,
                    type: type === 'goals' ? 'task' : 'goal'
                })
            })

        setMigratorData({
            ...MigratorData,
            ...type === 'goals' ? { _goals: MCards } : { _objectives: MCards }
        })
    }
    const finishMigration = async () => {
        await updateCard(props, {
            _id: MigratorData._id,
            type: MigratorData.type
        })
        setMigrator(false)
        setMigratorData({})
    }

    const selectCards = (selectedCardState, cardID, cardArr) => {

        let newData = { ...selectedCardState }
        let parent = reduxStore.getState().db.cards[cardID]._parent



        if (!selectedCardState.checkSelectedCards.includes(cardID) && !selectedCardState.childrenChecked.includes(cardID) && !selectedCardState.checkSelectedCards.includes(parent)) {
            newData.checkSelectedCards = [...selectedCardState.checkSelectedCards, cardID]
        } else if (selectedCardState.checkSelectedCards.includes(parent)) {
            if (!selectedCardState.childrenChecked.includes(cardID)) {
                newData.childrenChecked = [...selectedCardState.childrenChecked, cardID]
            } else {
                newData.childrenChecked = selectedCardState.childrenChecked.filter(Id => Id !== cardID)
            }
        }
        else {
            newData.checkSelectedCards = selectedCardState.checkSelectedCards.filter(Id => Id !== cardID)
            newData.childrenChecked = selectedCardState.childrenChecked.filter(Id => Id !== cardID)
        }



        let children = (Object.keys(getChildrens(cardID)) || [])
        children.filter(Id => Id !== cardID).map((item) => {
            if (newData.childrenChecked.includes(item)) {
                newData.childrenChecked = newData.childrenChecked.filter(Id => Id !== item)
            } else if (newData.childrenChecked.includes(cardID) || newData.checkSelectedCards.includes(cardID)) {

                newData.childrenChecked = [...newData.childrenChecked, item]
            }
        })

        setSelectedCardState(newData)
    }

    return (
        <div>
            <DragDropContext
                onDragEnd={onDragEnd}
            // onDragStart={onDragStart}
            // onDragUpdate={onDragUpdate}
            >
                <SelectedMenu
                    selectCards={selectedCardState}
                    handleCheckBoxState={() => props.handleCheckBoxState()}
                    handleCardsUpdate={(value) => setCardsUpdate(value)}
                    handleState={() => setSelectedCardState({
                        checkSelectedCards: [],
                        childrenChecked: []
                    })} />
                <Droppable
                    droppableId="ALL-COLUMNS-TYPE"
                    type="column"
                    direction={`${props.inLine ? 'vertical' : 'horizontal'}`}
                    isDropDisabled={true}
                >
                    {provided => (
                        <Container
                            {...props}
                            ref={provided.innerRef}
                        >
                            {overview.map((st, index) => {
                                const theColumn = {
                                    name: st.label,
                                    icon: st.icon,
                                    cards: [],
                                    emptyHide: false,
                                }

                                return <Column
                                    {...props}
                                    key={st.value}
                                    columnId={st.type}
                                    isDropDisabled
                                    column={theColumn}
                                    setSelectedCards={(cardID, cardArr) => selectCards(selectedCardState, cardID, cardArr)}
                                    selectedCards={[...selectedCardState?.checkSelectedCards, ...selectedCardState?.childrenChecked]}
                                    showCheck={props.showCheck}
                                    cards={
                                        cards
                                            && cards[st.value]
                                            ? cards[st.value].map(a => a._id)
                                            : []
                                    }
                                    index={index}
                                    columnsSettings={false}
                                    locked={false}
                                    columnStyle={{
                                        minWidth: '200px'
                                    }}
                                    viewButton={st.viewButton}
                                    isCombineEnabled
                                    inLineAdd
                                    typeAdd={st.type}
                                    selectedParent={st.selectedParent}
                                    cardColorPicker={props.showColors ? true : false}
                                />
                            })}
                            {provided.placeholder}
                        </Container>
                    )}
                </Droppable>
            </DragDropContext >
            <Dialog
                fullWidth={true}
                aria-labelledby="simple-dialog-title"
                open={Migrator}
            >
                <div style={{ position: "relative", marginLeft: "10px", marginTop: '5px', width: "calc(100% - 10px)" }}>
                    <HeaderWithIcon
                        title={translate("$__attention", 1)}
                        icon="label"
                        onCancel={() => {
                            setMigrator(false)
                            setMigratorData({})
                        }}
                    />
                </div>
                <div style={{ color: "#CCCCCC", padding: "15px" }}>
                    {
                        MigratorData._childrens
                        && !MigratorData._skipGoals
                        && MigratorData._goals.length > 0
                        && MigratorData._goals.filter(a => reduxStore.getState().db.cards[a._id] && reduxStore.getState().db.cards[a._id].type === a.type).length > 0
                        &&
                        <React.Fragment>
                            <span style={{ color: 'gray' }}>{translate(`$__YouHaveLinkedGoals`)}
                                <p style={{ color: 'red' }}>{translate(`$__ThisActionCanOnlyBeUndoneManually`)}</p>
                            </span>
                            {MigratorData._goals
                                .filter(a => reduxStore.getState().db.cards[a._id] && reduxStore.getState().db.cards[a._id].type === a.type)
                                .sort((a, b) => {
                                    if (reduxStore.getState().db.orderByTitle
                                        && reduxStore.getState().db.orderByTitlePosition === 'ASC'
                                        && a.name.toLowerCase().trim() < b.name.toLowerCase().trim()
                                    ) {
                                        return -1
                                    } else if (reduxStore.getState().db.orderByTitle
                                        && reduxStore.getState().db.orderByTitlePosition === 'DESC'
                                        && a.name.toLowerCase().trim() > b.name.toLowerCase().trim()
                                    ) {
                                        return -1
                                    } else if (reduxStore.getState().db.orderByTitle) {
                                        return 0
                                    } else if (!reduxStore.getState().db.orderByTitle && String(a.order) && String(b.order) && String(a.order.low) && String(b.order.low) && parseInt(a.order.low) < parseInt(b.order.low)) {
                                        return -1
                                    } else {
                                        return 1
                                    }
                                }
                                ).map((a, i) =>
                                    <div style={{ width: 'calc(100%)', display: "flex", alignItems: "center", justifyContent: "center" }} key={a._id}>
                                        <Card db="cards" cardId={a._id} noShadow />
                                        <Button
                                            onClick={() => {
                                                updateCard(props, {
                                                    _id: a._id,
                                                    type: 'task'
                                                })
                                            }}
                                        >
                                            <Icon
                                                icon="change_circle"
                                                color={customApp('menu')}
                                                title={translate('$__transformInTask')}
                                            />
                                        </Button>
                                    </div>
                                )
                            }
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "15px" }}>
                                <CustomButton
                                    shadow
                                    title={translate("$__skipAll")}
                                    text={translate("$__skipAll")}
                                    color="lightgrey"
                                    icon={"skip_next"}
                                    size={"25px"}
                                    onClick={() => {
                                        setMigratorData({
                                            ...MigratorData,
                                            _skipGoals: true
                                        })
                                    }}
                                />
                                <div style={{ marginLeft: "15px" }}>
                                    <CustomButton
                                        shadow
                                        title={translate("$__changeAllInTasks")}
                                        text={translate("$__changeAllInTasks")}
                                        icon={"change_circle"}
                                        size={"25px"}
                                        onClick={() => {
                                            iniMigration('goals')
                                            setMigratorData({
                                                ...MigratorData,
                                                _skipGoals: true
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    {
                        MigratorData._childrens
                        && !MigratorData._skipObjectives
                        && MigratorData._objectives.length > 0
                        && MigratorData._objectives
                            .filter(a => reduxStore.getState().db.cards[a._id] && reduxStore.getState().db.cards[a._id].type === a.type).length > 0
                        &&
                        <React.Fragment>
                            <span style={{ color: 'gray' }}>{translate(`$__YouHaveLinkedObjectives`)}
                                <p style={{ color: 'red' }}>{translate(`$__ThisActionCanOnlyBeUndoneManually`)}</p>
                            </span>
                            {MigratorData._objectives
                                .filter(a => reduxStore.getState().db.cards[a._id] && reduxStore.getState().db.cards[a._id].type === a.type)
                                .sort((a, b) => {
                                    if (reduxStore.getState().db.orderByTitle
                                        && reduxStore.getState().db.orderByTitlePosition === 'ASC'
                                        && a.name.toLowerCase().trim() < b.name.toLowerCase().trim()
                                    ) {
                                        return -1
                                    } else if (reduxStore.getState().db.orderByTitle
                                        && reduxStore.getState().db.orderByTitlePosition === 'DESC'
                                        && a.name.toLowerCase().trim() > b.name.toLowerCase().trim()
                                    ) {
                                        return -1
                                    } else if (reduxStore.getState().db.orderByTitle) {
                                        return 0
                                    } else if (!reduxStore.getState().db.orderByTitle && String(a.order) && String(b.order) && String(a.order.low) && String(b.order.low) && parseInt(a.order.low) < parseInt(b.order.low)) {
                                        return -1
                                    } else {
                                        return 1
                                    }
                                }
                                ).map((a, i) =>
                                    <div style={{ width: 'calc(100%)', display: "flex", alignItems: "center", justifyContent: "center" }} key={a._id}>
                                        <Card db="cards" cardId={a._id} noShadow />
                                        <Button
                                            onClick={() => {
                                                updateCard(props, {
                                                    _id: a._id,
                                                    type: 'goal'
                                                })
                                            }}
                                        >
                                            <Icon
                                                icon="change_circle"
                                                color={customApp('menu')}
                                                title={translate('$__transformInGoal')}
                                            />
                                        </Button>
                                    </div>
                                )
                            }
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "15px" }}>
                                <CustomButton
                                    shadow
                                    title={translate("$__skipAll")}
                                    text={translate("$__skipAll")}
                                    color="lightgrey"
                                    icon={"skip_next"}
                                    size={"25px"}
                                    onClick={() => {
                                        setMigratorData({
                                            ...MigratorData,
                                            _skipObjectives: true
                                        })
                                    }}
                                />
                                <div style={{ marginLeft: "15px" }}>
                                    <CustomButton
                                        shadow
                                        title={translate("$__changeAllInGoal")}
                                        text={translate("$__changeAllInGoal")}
                                        icon={"change_circle"}
                                        size={"25px"}
                                        onClick={() => {
                                            iniMigration('objectives')
                                            setMigratorData({
                                                ...MigratorData,
                                                _skipObjectives: true
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ marginLeft: "15px" }}>
                                <CustomButton
                                    shadow
                                    title={translate("$__cancel")}
                                    text={translate("$__cancel")}
                                    icon={"cancel"}
                                    size={"25px"}
                                    onClick={() => {
                                        setMigrator(false)
                                        setMigratorData({})
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    }
                    {(
                        (
                            MigratorData
                            && MigratorData._childrens
                            && Object.keys(MigratorData._childrens).length > 0
                            && MigratorData._goals.filter(a => reduxStore.getState().db.cards[a._id] && reduxStore.getState().db.cards[a._id].type === a.type).length === 0
                            && MigratorData._objectives.filter(a => reduxStore.getState().db.cards[a._id] && reduxStore.getState().db.cards[a._id].type === a.type).length === 0
                        )
                        || (
                            (
                                (
                                    MigratorData._skipGoals
                                    && MigratorData._goals.length > 0
                                )
                                ||
                                (
                                    MigratorData
                                    && MigratorData._goals
                                    && MigratorData._goals.length === 0
                                )
                            )
                            &&
                            (
                                (
                                    MigratorData._skipObjectives
                                    && MigratorData._objectives.length > 0
                                )
                                ||
                                (
                                    MigratorData
                                    && MigratorData._objectives
                                    && MigratorData._objectives.length === 0
                                )
                            )
                        )
                    )
                        &&
                        <React.Fragment>
                            <span style={{ color: 'red' }}>{translate("$__clickInSaveToFinishMigration")}</span>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "15px" }}>
                                <CustomButton
                                    shadow
                                    title={translate("$__cancel")}
                                    text={translate("$__cancel")}
                                    color="lightgrey"
                                    icon={"close"}
                                    size={"25px"}
                                    onClick={() => {
                                        setMigrator(false)
                                        setMigratorData({})
                                    }}
                                />
                                <div style={{ marginLeft: "15px" }}>
                                    <CustomButton
                                        shadow
                                        title={translate("$__save")}
                                        text={translate("$__save")}
                                        icon={"done_all"}
                                        size={"25px"}
                                        onClick={() => {
                                            finishMigration()
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </Dialog>
        </div >
    )
}

//STYLEDS
const Container = styled.div`
position:absolute;
height:100%;
padding:7px;
${props =>
        !props.inLine && props.styles && props.styles.discountHeight && `height:calc(100vh - ${props.styles.discountHeight}px);`
    }
${props =>
        props.inLine ?
            `display: block;
                width: -webkit-fill-available;
                ${props.style}`
            :
            `   display: flex;
                width: 100%;
                ${props.style}
                @media(min-width: 960px) {
                    display: flex;
                }
                `
    }
    @media(max-width: 959px) {
        display: contents !important;
        width: -webkit-fill-available;
        height:auto;
      }
    `

//REACT
const mapStateToProps = (store, props) => ({
    cards: store.db.cards,
    searchs: store.searchs,
    controls: store.controls
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);