import React from "react";

//REDUX
import * as reduxActions from "store/actions";
import history from "store/history";
import reduxStore from "store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//COMPONENTS
import IconButton from "components/CustomButtons/IconButton";
import TextField from "components/TextField";
import ImgCropper from "components/Cropper";
import Icon from "components/Icon";
import HeaderWithIcon from "components/Header/card";
import LinearProgress from "components/Progress/linear";

//@MATERIAL
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/Card";

import {
    translate,
    customApp,
} from "functions/";

import {
    saveCard,
    copyCards,
} from "functions/cards";

import {
    loadCardsV2
} from "functions/loadData";

function Card(props) {
    const { db } = reduxStore.getState();
    const [data, setData] = React.useState({})

    React.useEffect(() => {
        setData({
            ...props.toTemplate && db.cards[props.cardId].name ? { name: `${translate('$__template')} - ${db.cards[props.cardId].name}` } : {},
            ...props.toTemplate && db.cards[props.cardId].description ? { description: db.cards[props.cardId].description } : {},
            ...props.toTemplate && db.cards[props.cardId].image ? { image: db.cards[props.cardId].image } : {},
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [errorData, setErrorData] = React.useState({})
    const [installProgress, setInstallProgress] = React.useState(0)

    const dataType = reduxStore.getState().db.cards[props.cardId] && reduxStore.getState().db.cards[props.cardId].type ? db.dataType.filter(a => a.name === reduxStore.getState().db.cards[props.cardId].type)[0] : {}

    // console.log(data)
    const installTemplate = async () => {
        if (data && !data.name) {
            setErrorData({
                ...errorData,
                name: true
            })
        } else {
            setInstallProgress(1)
            try {
                let dataSend = {
                    ...db.cards[props.cardId],
                    ...data,
                    templateId: db.cards[props.cardId]._id,
                    created_at: null,
                    isTemplate: props.toTemplate ? true : null,
                    order: null,
                    updated_at: null,
                    _id: null,
                    _planId: null,
                }
                const request = await saveCard(props, true, dataSend);
                if (request && request._id) {
                    setInstallProgress(2)
                    setTimeout(() => {
                        installStep2(request._id)
                    }, 1500)
                }
            } catch (e) {

            }
        }
    }

    const installStep2 = async (id) => {
        try {
            const request2 = await copyCards(props, props.cardId, id);
            if (request2) {
                setInstallProgress(3)
                setTimeout(() => {
                    installStep3(id)
                }, 1500)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const installStep3 = async (id) => {
        const loadPlan = await loadCardsV2(props, { idRel: [id], cardLoad: 'plans' })
        const loadPlanCompleted = await loadCardsV2(props, { idRel: [id], cardLoad: 'plans', completed: true })
        if (loadPlan && loadPlanCompleted) {
            setInstallProgress(4)
            setTimeout(() => {
                if (props.onComplete)
                    props.onComplete()

                if (props.toTemplate) {
                    history.push(`sp#tab/templates`)
                } else {
                    history.push(`sp#${id}/dashboard`)
                }
            }, 500)

        }
    }

    if (installProgress > 0)
        return (
            <Paper style={{ padding: '7px', }}>
                <HeaderWithIcon
                    title={`${data.name ? data.name : db.cards[props.cardId].name} `}
                    icon={dataType && dataType.icon ? dataType.icon : 'help'}
                    iconTitle={`$__${db.cards[props.cardId].type}`}
                    color={customApp('medium')}
                    fontSize={"16px"}
                />
                {installProgress === 1 &&
                    <div style={{ position: 'relative', float: 'left', width: '100%', clear: "both" }}>
                        <div style={{ position: 'relative', float: 'left', width: '100%' }}>{translate('$__installingTemplate')}</div>
                        <LinearProgress />
                    </div>
                }

                {installProgress === 2 &&
                    <React.Fragment>
                        <div style={{ position: 'relative', float: 'left', width: '100%', display: "flex" }}>
                            <div style={{ position: 'relative', float: 'left', width: '100%' }}>{translate('$__templateInstalled')}</div>
                            <Icon icon='done_all' color={'green'} />
                        </div>
                        <div style={{ position: 'relative', float: 'left', width: '100%', marginTop: '15px' }}>
                            <div style={{ position: 'relative', float: 'left', width: '100%' }}>{translate('$__copyingData')}</div>
                            <LinearProgress />
                        </div>
                    </React.Fragment>
                }
                {installProgress === 3 &&
                    <React.Fragment>
                        <div style={{ position: 'relative', float: 'left', width: '100%', display: "flex" }}>
                            <div style={{ position: 'relative', float: 'left', width: '100%' }}>{translate('$__templateInstalled')}</div>
                            <Icon icon='done_all' color={'green'} />
                        </div>
                        <div style={{ position: 'relative', float: 'left', width: '100%', display: "flex", marginTop: '15px' }}>
                            <div style={{ position: 'relative', float: 'left', width: '100%' }}>{translate('$__copiedData')}</div>
                            <Icon icon='done_all' color={'green'} />
                        </div>
                        <div style={{ position: 'relative', float: 'left', width: '100%', marginTop: '15px' }}>
                            <div style={{ position: 'relative', float: 'left', width: '100%' }}>{translate('$__preparingToRedirect')}</div>
                            <LinearProgress />
                        </div>
                    </React.Fragment>
                }
                {installProgress === 4 &&
                    <div style={{
                        position: 'relative',
                        width: '100%',
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        <div style={{
                            position: 'relative',
                            float: 'left',
                            padding: '15px',
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: '50%',
                            border: 'solid 1px green'
                        }}>
                            <Icon icon='done_all' color={'green'} size={'50px'} />
                        </div>
                    </div>
                }
                <div style={{ clear: "both" }}></div>
            </Paper>
        )

    return (
        <Paper style={{ padding: '7px' }}>
            <HeaderWithIcon
                title={`${translate('$__template', 1)}: ${db.cards[props.cardId].name}`}
                icon={dataType && dataType.icon ? dataType.icon : 'help'}
                iconTitle={`$__${db.cards[props.cardId].type}`}
                color={customApp('medium')}
                fontSize={"16px"}
            />
            <div>
                <ImgCropper
                    img={props.toTemplate && db.cards[props.cardId].image ? db.cards[props.cardId].image : null}
                    onChange={(a, file) => {
                        setData({
                            ...data,
                            image: a,
                            file: file
                        })
                    }}
                    placeholder={''}
                />
                <TextField
                    error={errorData && errorData.name}
                    // helperText={translate("$__youMustDefineAName")}
                    label={`${translate('$__customName', 1)}`}
                    variant={customApp('fieldVariant')}
                    name={'name'}
                    defaultValue={props.toTemplate && db.cards[props.cardId].name ? `${translate('$__template')} - ${db.cards[props.cardId].name}` : null}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        autoComplete: 'new-password',
                        endAdornment: (
                            <InputAdornment position="start">
                                <Icon icon='view_headline' />
                            </InputAdornment>
                        ),
                        onChange: (d) => {
                            setData({
                                ...data,
                                name: d.target.value
                            })
                            if (errorData && errorData.name)
                                setErrorData({
                                    ...errorData,
                                    name: false
                                })
                        },
                    }}
                    // value={data && data.name ? data.name : ''}
                    placeholder={translate(props.toTemplate ? '$__templateName_placeholder' : '$__planName_placeholder', 1)}
                // disabled={!props.permission || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf('name') > -1) ? true : false}
                />
                <TextField
                    label={`${translate('$__customDescription', 1)}`}
                    variant={customApp('fieldVariant')}
                    name={'description'}
                    defaultValue={props.toTemplate && db.cards[props.cardId].description ? db.cards[props.cardId].description : null}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        autoComplete: 'new-password',
                        endAdornment: (
                            <InputAdornment position="start">
                                <Icon icon='toc' />
                            </InputAdornment>
                        ),
                        onChange: (d) => {
                            setData({
                                ...data,
                                description: d.target.value
                            })
                        },
                    }}
                    placeholder={translate(props.toTemplate ? '$__templateDescription_placeholder' : '$__planDescription_placeholder', 1)}
                    multiline
                />
            </div>

            <div
                style={{
                    position: "relative",
                    width: "100%",
                    clear: "both",
                    padding: "7px",
                    backgroundColor: "#4caf50",
                    marginTop: "7px",
                    cursor: "pointer"
                }}
                onClick={(e) => {
                    installTemplate()
                    e.stopPropagation()
                }}>

                <IconButton
                    text={translate("$__ready", '*')}
                    icon="done_all"
                    iconColor={'#FFFFFF'}
                    textColor={'#FFFFFF'}
                />
            </div>
            <div style={{
                position: "relative",
                width: "100%",
                clear: "both",
                padding: "7px",
                backgroundColor: "lightgrey",
                cursor: "pointer"
            }}
                onClick={(e) => {
                    props.onComplete()
                    e.stopPropagation()
                }}>
                <IconButton
                    text={translate("$__cancel", '*')}
                    icon="close"
                    iconColor={'#FFFFFF'}
                    textColor={'#FFFFFF'}
                    onClick={props.onComplete ? () => {
                        props.onComplete()
                    } : null}
                />
            </div>
        </Paper >
    )
}

//REACT
const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Card));