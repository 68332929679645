import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store"

//COMPONENTS
import HeaderWithIcon from "components/Header/withIcon"
import Icon from "components/Icon"
import TextField from "components/TextField/"

//@MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';

//FUNCTIONS
import {
    translate,
} from "functions/"

import { customApp } from "functions"

import { navFolders } from "functions/files"

function Navigation(props) {
    const { db } = reduxStore.getState()
    const [selectedParent, setSelectedParent] = React.useState(null)
    const [folders, setFolders] = React.useState({})
    const [searchFolder, setSearchFolder] = React.useState("")

    let idPlan = props.idPlan ? props.idPlan : null
    const ignoreCards = props.ignoreCards ? props.ignoreCards : []

    React.useEffect(() => {
        reqFolders({ id: idPlan })
        navFolders()
        return () => {
            setFolders({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const reqFolders = async (data, fds) => {
        let lf = await navFolders({
            ids: data && data.id ? [data.id] : [],
            childrens: false,
            types: props && props.types ? props.types : null
        })

        if (lf) {
            let ff = fds ? fds : folders
            if (idPlan) {
                ff = {
                    ...ff,
                    [idPlan]: {
                        name: reduxStore.getState().db.cards[idPlan].name,
                        parent: reduxStore.getState().db.cards[idPlan]._parent,
                        private: null,
                        type: "plan",
                        _id: idPlan,
                        open: true,
                    }
                }
            }
            lf.filter(folder => folder.type !== "chatGroup" && !ignoreCards.includes(folder)).forEach(folder => {
                ff = {
                    ...ff,
                    [folder._id]: {
                        ...ff[folder._id] ? ff[folder._id] : {},
                        ...folder,
                    }
                }
            })
            setFolders(ff)
        }
    }

    const openFolder = async (data, option = false) => {
        const { id = null, type = null } = data
        let nFolders = folders

        nFolders = {
            ...nFolders,
            [id]: {
                ...nFolders[id],
                open: option,
                loading: true,
            }
        }
        if (Object.keys(folders).filter(a => {
            if (folders[a].parent === id) return true
            return false
        }).length === 0) {
            reqFolders({ id, type }, nFolders)
        } else {
            setFolders(nFolders)
        }
    }

    const RenderFolder = (folder, f) => {
        if (ignoreCards.includes(folder._id)) {
            return <></>
        }
        return <div
            key={f}
            style={{
                position: "relative",
                float: "left",
                clear: "both",
                cursor: "pointer",
                borderLeft: "solid 0.5px lightgray",
                paddingLeft: 7,
                width: "100%",
            }}
            onClick={(e) => {
                if (!folder.private ||
                    (folder.private && reduxStore.getState().controls.lockPrivate)
                ) {
                    openFolder({
                        id: f,
                    }, folders[f] && folders[f].open ? false : true)
                    setSelectedParent(f)
                    if (props && props.onSelect) {
                        console.log(folders[f])
                        props.onSelect(folders[f])
                    }
                } else {

                }
                e.preventDefault()
                e.stopPropagation()
            }}
        >
            <HeaderWithIcon
                title={`${folder.private && !reduxStore.getState().controls.lockPrivate ? translate("$__private") : folder.name}`}
                tooltipText={`${folder.private && !reduxStore.getState().controls.lockPrivate ? translate("$__private") : folder.name}`}
                icon={(folder.private && !reduxStore.getState().controls.lockPrivate ? 'private_connectivity' : folder.type && db && db.dataType && db.dataType.filter(a => a.name === folder.type)[0].icon) || "$__topic"}
                fontSize="12px"
                color={folder.private && !reduxStore.getState().controls.lockPrivate ? 'lightgray' : selectedParent && selectedParent === f ? customApp("color") : customApp("gray")}
            />
            {folders[f] && folders[f].open ? reqSubFolders(f) : <React.Fragment></React.Fragment>}
        </div>
    }

    const reqSubFolders = (id = null) => {
        return (
            <div style={{
                marginLeft: 7
            }}>
                {Object.keys(folders).filter(folder =>
                (
                    (
                        !id
                    )
                    ||
                    (
                        id &&
                        folders[folder].parent === id
                    )
                )
                ).length > 0 ?
                    Object.keys(folders).filter(folder =>
                    (
                        (
                            !id
                        )
                        ||
                        (
                            id &&
                            folders[folder].parent === id
                        )
                    )
                    ).sort((a, b) => {
                        if (folders[a].step && folders[b].step && parseInt(folders[a].step) < parseInt(folders[b].step))
                            return -1
                        if (folders[a].step && folders[b].step && parseInt(folders[a].step) > parseInt(folders[b].step))
                            return 1
                        if (folders[a].name.toLowerCase().trim() < folders[b].name.toLowerCase().trim())
                            return -1
                        if (folders[a].name.toLowerCase().trim() > folders[b].name.toLowerCase().trim())
                            return 1
                        return 0
                    }).map(f => {
                        return RenderFolder(folders[f], f)
                    }) : <React.Fragment></React.Fragment>
                }
            </div>
        )
    }

    return (
        <div >
            <div style={{
                position: "relative",
                backgroundColor: "#FFFFFF",
                clear: "both",
                paddingTop: "15px"
            }}>
                <div>
                    <div style={{ display: "flex", marginTop: -10 }}>
                        {/* <HeaderWithIcon
                            title={translate("$__selectLocation", 1)}
                            icon={"touch_app"}
                            fontSize="20px"
                            color={customApp("menu")}
                        /> */}
                        <TextField
                            label={translate('$__search', '*')}
                            variant={'standard'}
                            autoFocus
                            value={searchFolder}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <Icon color={customApp('lightgray')}>search</Icon>
                                    </InputAdornment>
                                ),
                                onChange: e => {
                                    setSearchFolder(e.target.value)
                                },
                                onKeyDown: e => {
                                    // if (String(e.which) === '27')
                                    //     setviewSearch(false)
                                }
                            }}
                        />
                    </div>
                    <div style={{
                        position: "relative",
                        maxHeight: "40vh",
                        overflowX: "auto",
                        backgroundColor: "rgba(250,250,250,0.9)",
                        borderRadius: "solid 0.5px rgba(0,0,0,0.1)",
                        padding: 15,
                        ...props.styles ? props.styles : {}
                    }}>
                        {Object.keys(folders).filter(folder => {
                            return folders[folder].type !== "chatGroup"
                                && (
                                    (
                                        (
                                            !idPlan
                                            ||
                                            (
                                                idPlan
                                                && idPlan.length !== 36
                                            )
                                        )
                                        &&
                                        !folders[folder].parent
                                    )
                                    ||
                                    (
                                        (
                                            idPlan
                                            && idPlan.length === 36
                                        )
                                        && (
                                            (
                                                folders[folder]._id === idPlan
                                            )
                                        )
                                    )
                                )
                                &&
                                (
                                    searchFolder === ""
                                    ||
                                    (
                                        searchFolder
                                        && folders[folder].name.toLowerCase().indexOf(searchFolder.toLowerCase().trim()) > -1
                                    )
                                )
                        }).sort((a, b) => {
                            if (folders[a].name.toLowerCase().trim() < folders[b].name.toLowerCase().trim())
                                return -1
                            if (folders[a].name.toLowerCase().trim() > folders[b].name.toLowerCase().trim())
                                return 1
                            return 0
                        }).map(f => {
                            return RenderFolder(folders[f], f)
                        }
                        )}
                    </div>
                </div>
            </div>

        </div >
    )
}

const mapStateToProps = (store) => ({
    showPrivate: store.controls.lockPrivate
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps,
    mapDispatchToProps
)(Navigation)