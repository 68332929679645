import React, { useRef } from 'react'
import Icon from 'components/Icon'
import ButtonOptions from 'componentsV3/Buttons';
import Filter from 'componentsV3/FilterDashboard/index';
import TextField from "components/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"

const Header = (props) => {
    const { tittle, icon, datafilter, handleSetoption, handleSetSelected } = props
    const containerRef = useRef()

    return (
        <div ref={containerRef} style={{ height: '47px', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', background: props.styles.backgroundColor ? props.styles.backgroundColor : 'white', display: 'flex', fontWeight: 'bolder', position: 'relative', overflow: 'visible' }}>
            <div style={{ width: '40px', height: '40px', alignSelf: 'center', display: 'flex' }}>
                <Icon
                    size={28}
                    icon={icon && icon}
                    style={{
                        alignSelf: 'center',
                        marginLeft: '12.5px',
                        color: props.styles.color ? props.styles.color : 'grey',
                        maxWidth: '30px'
                    }} />
            </div>
            {props.edit ? <div >
                <TextField
                    variant={"outlined"}
                    name={"name"}
                    id={"edit_693"}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => handleSetSelected && handleSetSelected({
                        name: e.target.value
                    })}
                    linecolor={'#D3D3D3'}
                    style={{ minWidth: '0px', width: '160px' }}
                    InputProps={{
                        autoComplete: "new-password",
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon icon="edit" />
                            </InputAdornment>
                        ),
                        // onChange: (d) => {
                        //     changeData(d.target)
                        // },
                    }}
                    defaultValue={props.indicators?.name ? props.indicators?.name : tittle}
                    placeholder={"TESTEEEEE"}
                />
            </div>
                //             <input
                //     type="text"
                //     defaultValue={props.indicators?.name ? props.indicators?.name : tittle}
                //     onBlur={(e) => handleSetSelected({
                //         name: e.target.value
                //     })}
                // />
                : <p style={{ margin: '0px', marginLeft: '10px', alignSelf: 'center', padding: '0px', fontSize: '14px', fontWeight: 'bolder' }}>
                    {props.indicators?.name ? props.indicators?.name : tittle}
                </p>}
            {props.edit &&
                <>
                    <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                        onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                        <Icon onClick={() => props.handleRemoveObject(props.id)} style={{ height: '25px', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', cursor: 'pointer', position: 'absolute', right: '20px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                            close
                        </Icon>
                    </div>
                    <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                        onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                        <Icon className='drag-handle' style={{ height: '25px', cursor: 'move', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', position: 'absolute', right: '62px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                            open_with
                        </Icon>
                    </div>
                    {datafilter && <ButtonOptions
                        text={"Filtro"}
                        icon={"filter_alt"}
                        popper={
                            <div style={{ padding: '15px', }}>
                                <div style={{ marginLeft: '11px', marginBottom: '12px' }}>
                                    {props?.filterConfig?.filterSelect && <select
                                        placeholder={"Quantidade de usuarios por exibição"}
                                        onChange={(e) => handleSetSelected({
                                            finalIndex: parseInt(e.target.value),
                                            showQuantity: parseInt(e.target.value), index: 0, number: parseInt(e.target.value)
                                        })}
                                        style={{ padding: '10px 10px 10px 3px', alignSelf: 'center', border: '1px solid #dde2e6', color: '#bebebe', fontSize: '14px' }}>
                                        <option disabled selected>Quantidade de {props?.filterConfig?.filterName ? props?.filterConfig?.filterName : 'usuarios'} por exibição</option>
                                        {props?.filterConfig?.filterSelect > 5 && <option > 5 {props?.filterConfig?.filterName ? props?.filterConfig?.filterName : 'usuarios'} por exibição</option>}
                                        {props?.filterConfig?.filterSelect > 10 && <option > 10 {props?.filterConfig?.filterName ? props?.filterConfig?.filterName : 'usuarios'} por exibição</option>}
                                        {props?.filterConfig?.filterSelect > 15 && <option > 15 {props?.filterConfig?.filterName ? props?.filterConfig?.filterName : 'usuarios'} por exibição</option>}
                                        {props?.filterConfig?.filterSelect > 10 && <option > 20 {props?.filterConfig?.filterName ? props?.filterConfig?.filterName : 'usuarios'} por exibição</option>}
                                        {props?.filterConfig?.filterSelect > 10 && <option > 25 {props?.filterConfig?.filterName ? props?.filterConfig?.filterName : 'usuarios'} por exibição</option>}
                                    </select>}
                                </div>

                                <Filter
                                    fields={props?.filterConfig?.filterItems ? props?.filterConfig?.filterItems : ['date', 'users']}
                                    showFilters
                                    style={{ position: 'absolute', zIndex: '999' }}
                                    nodeId={"barChart"}
                                    indicators={props?.indicators}
                                    onChange={(e) => {
                                        handleSetoption(e)
                                    }}
                                />
                            </div>
                        }
                        style={{
                            alignSelf: 'center',
                            position: 'absolute',
                            right: '107px',
                        }}
                        textColor={props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)'}
                        btColor={props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)'}
                    />}
                    <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                        onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                        {props?.table && <Icon onClick={() => props.handleModal()} style={{ height: '25px', cursor: 'pointer', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', position: 'absolute', right: '232px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                            table_rows
                        </Icon>}
                    </div>
                    <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                        onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                        {props?.vertical && <Icon onClick={() => handleSetSelected({
                            mode: (!props.indicators.mode || false)
                        })} style={{ height: '25px', cursor: 'pointer', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', position: 'absolute', right: '278px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                            vertical_split
                        </Icon>}
                    </div>
                </>
            }

        </div >
    )
}

export default Header