//CORE
import React from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

//COMPONENTS
import AgileOverview from "components/AgileV2/overview";
import CustomButton from "components/Buttons/custom";
import Filter from "componentsV3/Filter/menuFilter";
import MoreMenu from "components/MoreMenu";

//FUNCTIONS
import {
    customApp,
    translate,
} from "functions/";

import {
    countNotifications
} from "functions/cards"

import {
    loadCardsV2,
} from "functions/loadData"


function StrategyAndPlansOverviewView(props) {
    const { db } = reduxStore.getState()
    const [viewCompleted, setViewCompleted] = React.useState(false)
    const [viewColors, setViewColors] = React.useState(true)
    const [onlyUnReaded, setOnlyUnReaded] = React.useState(false)
    const [showDeleted, setShowDeleted] = React.useState(false)
    const [loadingDeleted, setLoadingDeleted] = React.useState(false)
    const [showCheck, setShowCheck] = React.useState(false)

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    React.useEffect(() => {
        return () => {
            props.reduxFunction("ASYNC", "SET_CONTROL", {
                ...reduxStore.getState().controls,
                deleted: false
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (showDeleted)
            loadDeletedCards()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDeleted])

    const loadDeletedCards = async () => {
        setLoadingDeleted(true)
        let Nhashs = window.location.hash.split("/")
        Nhashs[0] = Nhashs[0].replace("#", "")

        let lc = await loadCardsV2(props, {
            idRel: Nhashs[0],
            cardLoad: 'deleted',
        })
        if (lc)
            setLoadingDeleted(false)
    }

    let unReadeds = 0
    Object.keys(db.cards).filter(a => {
        let notifications = countNotifications(a)
        if (db.cards[a]
            && !db.cards[a].deleted
            && (
                db.cards[a]._planId === hashs[0]
                || db.cards[a]._parent === hashs[0]
            )
            && (
                viewCompleted
                ||
                (
                    !viewCompleted
                    && db.cards[a].status !== "completed"
                )
            )
            && notifications.total > 0
        )
            return true
        return false
    }).forEach(a => {
        let notifications = countNotifications(a)
        unReadeds = unReadeds + notifications.total
    })

    return (
        <div >
            <div style={{
                padding: "0px 10px"
            }}>
                <Filter style={{
                    marginLeft: '5px',
                    paddingTop: "10px"
                }}
                    fields={['text', 'date', 'tags', 'priority', 'unit', 'usersMode', 'users', 'responsible']}
                    showFilters
                >
                    <div
                        style={{
                            display: "flex",
                        }}
                    >
                        <MoreMenu
                            text={translate("$__options")}
                            icon={"settings"}
                            horizontalButton
                            size={'21px'}
                            transparent
                            style={{ border: '1px solid rgba(0, 0, 0, 0.47)', borderRadius: '5px' }}
                            color={customApp('menu')}
                            btColor={"lightgray"}
                            options={[
                                {
                                    name: db.orderByTitlePosition === 'ASC' ? translate("$__orderByAsc") : translate("$__orderByDesc"),
                                    icon: db.orderByTitlePosition === 'ASC' ? "text_rotation_down" : "text_rotate_up",
                                    onClick: () => {
                                        props.reduxFunction("ASYNC", "SET_DB", {
                                            ...reduxStore.getState().db,
                                            orderByTitlePosition: reduxStore.getState().db.orderByTitlePosition === 'ASC' ? 'DESC' : 'ASC'
                                        })
                                    },
                                },
                                {
                                    name: db.orderByTitle ? translate("$__manualOrder") : translate("$__orderByName"),
                                    icon: db.orderByTitle ? "drag_handle" : "format_list_numbered_rtl",
                                    onClick: () => {
                                        props.reduxFunction("ASYNC", "SET_DB", {
                                            ...reduxStore.getState().db,
                                            orderByTitle: !reduxStore.getState().db.orderByTitle
                                        })
                                    },
                                },
                                {
                                    name: viewColors ? translate("$__hideCatenationColors") : translate("$__showCatenationColors"),
                                    icon: "palette",
                                    color: !viewColors ? customApp('menu') : 'lightGray',
                                    onClick: () => {
                                        setViewColors(prev => !prev)
                                    },
                                },
                                {
                                    name: translate("$__groupByColor"),
                                    icon: "gradient",
                                    color: db.groupByColor ? customApp('menu') : 'lightGray',
                                    onClick: () => {
                                        props.reduxFunction("ASYNC", "SET_DB", {
                                            ...reduxStore.getState().db,
                                            groupByColor: !db.groupByColor
                                        })
                                    },
                                },
                            ]}
                        />
                        <div style={{ marginLeft: "9px", display: "flex", alignItems: "center" }}>
                            <CustomButton
                                title={viewCompleted ? translate(`$__hideCompleted`, '*') : translate(`$__selectCards`, '*')}
                                text={viewCompleted ? translate(`$__selectCards`, '*') : translate(`$__selectCards`, '*')}
                                color={showCheck ? customApp('menu') : "grey"}
                                icon={showCheck ? "toggle_on" : "toggle_off"}
                                transparent
                                onClick={() => {
                                    setShowCheck(prev => !prev)
                                }}
                                size={'21px'}
                                style={{ marginRight: "9px", padding: "0px !important", backgroundColor: 'transparent', border: '1px solid rgba(0, 0, 0, 0.47)', borderRadius: '5px' }}
                            />
                            <CustomButton
                                title={viewCompleted ? translate(`$__hideCompleted`, '*') : translate(`$__showCompleted`, '*')}
                                text={viewCompleted ? translate(`$__hideCompleted`, '*') : translate(`$__showCompleted`, '*')}
                                color={viewCompleted ? customApp('menu') : "grey"}
                                icon={viewCompleted ? "toggle_on" : "toggle_off"}
                                transparent
                                onClick={() => {
                                    setViewCompleted(prev => !prev)
                                }}
                                size={'21px'}
                                style={{ padding: "0px !important", backgroundColor: 'transparent', border: '1px solid rgba(0, 0, 0, 0.47)', borderRadius: '5px' }}
                            />
                            {unReadeds < 0 ?
                                <CustomButton
                                    title={onlyUnReaded ? translate(`$__hideUnreaded`, '*') : translate(`$__showUnreaded`, '*')}
                                    text={onlyUnReaded ? translate(`$__hideUnreaded`, '*') : translate(`$__showUnreaded`, '*')}
                                    color={onlyUnReaded ? customApp('menu') : 'lightGray'}
                                    icon={onlyUnReaded ? "toggle_on" : "toggle_off"}
                                    transparent
                                    onClick={() => {
                                        setOnlyUnReaded(prev => !prev)
                                    }}
                                    badgeContent={unReadeds}
                                    size={'21px'}
                                    style={{ marginLeft: "12px", padding: "0px !important", backgroundColor: 'transparent', border: '1px solid rgba(0, 0, 0, 0.47)', borderRadius: '5px' }}
                                />
                                : <React.Fragment></React.Fragment>
                            }
                            <div
                                style={{
                                    marginLeft: 15
                                }}
                            >
                                <CustomButton
                                    title={!reduxStore.getState().controls.deleted ? translate("$__showDeleted") : translate("$__hideDeleted")}
                                    color={!reduxStore.getState().controls.deleted ? customApp('menu') : 'red'}
                                    load={loadingDeleted}
                                    icon={"recycling"}
                                    transparent
                                    onClick={() => {
                                        setShowDeleted(prev => !prev)
                                        props.reduxFunction("ASYNC", "SET_CONTROL", {
                                            ...reduxStore.getState().controls,
                                            deleted: !showDeleted
                                        })
                                    }}
                                    size={'25px'}
                                    style={{ padding: "0px !important", marginLeft: '-6px', border: '1px solid rgba(0, 0, 0, 0.47)', backgroundColor: 'transparent', borderRadius: '5px' }}
                                />
                            </div>
                        </div>
                    </div>
                </Filter>
            </div>
            <AgileOverview
                nodeId={hashs[0] || null}
                showCompleted={viewCompleted}
                showCheck={showCheck}
                handleCheckBoxState={() => setShowCheck(prev => !prev)}
                showColors={viewColors}
                onlyUnReaded={onlyUnReaded}
                styles={{
                    discountHeight: 130
                }}
            />
        </div>
    )
}

const mapStateToProps = (store) => ({
    groupByColor: store.db.groupByColor,
    orderByTitle: store.db.orderByTitle,
    orderByTitlePosition: store.db.orderByTitlePosition,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StrategyAndPlansOverviewView)
