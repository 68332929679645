// ID_CREATED*************************

import React from "react"
import MD5 from "crypto-js/md5"
import cx from "classnames"
import moment from "moment-timezone"
import { Switch, Route, Redirect } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import PolicyAndTerms from "components/PlataformContracts/PolicyAndTerms"
import LicensingAgreement from "components/PlataformContracts/LicensingAgreement"
import ErrorBoundary from "components/ErrorBoundary"
import Notes from "componentsV3/Notes"


//@MATERIAL
import { makeStyles } from "@material-ui/core/styles"
import Backdrop from "@material-ui/core/Backdrop"
import Hidden from "@material-ui/core/Hidden"

// COMPONENTS
import Footer from "components/Footer/Footer"
import Icon from "components/Icon"
import LightBox from "components/LightBox"
import LoaderPage from "components/LoaderPage"

import MiroBoard from "components/MiroBoard"
import SideModule from "components/SideModule/"
import Sidebar from "components/Sidebar/"
import TopHeader from "components/TopHeader"
import Chat from "components/Chat"
import MobileNav from "components/MobileNav"
import MoveCards from "components/Quick/MoveCards"
import CardActions from "components/CardActions"
import KanbanModal from "components/AgileKanban/modal"
import CopyMove from "components/Quick/copy_move"
import CardAdd from "components/Quick/addCard"
import Tour from "componentsV3/Tour/Tour"

//ROUTES
import routes from "routes"

//FUNCTIONS
import apims from "apims/"

import {
  cardAccessDelete,
  cardAccessRemove,
  cardAccessUpdate,
  changeParentId,
  columnCards,
  dbUpdateCard,
  dbUpdateCardSWOT,
  dbUpdateCardTimer,
  dbUpdateCard_V2,
  deleteCard,
  deleteColumn,
  myDayUpd,
  reorderCard,
  reorderColumns,
  socketUnLinkChildrens,
  updateColumnCards,
} from "functions/cards"

import {
  chatReaded,
  interact,
  loadTimeline,
} from "functions/chat"

import {
  dbUpdateUser,
  loadUsersV2
} from "functions/users"

import {
  dbUpdateFeed
} from "functions/feeds"

import {
  appAlert,
  languageUPD,
} from "functions/"

import {
  nodeUpdate
} from "functions/socket"

import {
  tagUpdate,
  localTagDelete,
  localTagUnlink
} from "functions/tags"

import {
  loadCardsV2,
  pageLoadCards,
} from "functions/loadData"

import {
  kanbanReorder
} from "functions/kanban"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"
import history from "store/history"

// SOCKET
import socketIOClient from "socket.io-client"

//STYLESS
import stylesAdmin from "./adminStyle"
import stylesLogin from "./authStyle"
import "perfect-scrollbar/css/perfect-scrollbar.css"

//AUDIO
import { translate, customApp } from "functions"
import Call from "componentsV3/Call"
import AuthNavbar from "components/Navbars/AuthNavbar"

require('dotenv').config()

const API_WS = process.env.REACT_APP_WS

function SaasHome(props) {
  const { ...rest } = props
  const { session } = reduxStore.getState()
  const [whiteLabel, setWhiteLabel] = React.useState({})
  const [auth, setAuth] = React.useState(false)
  const [backdropEnabled, setBackdropEnabled] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [offlineDetected, setOfflineDetected] = React.useState(false)
  const [expanded, setExpanded] = React.useState(true)
  const [subMenuExpanded, setSubMenuExpanded] = React.useState(false)

  const useStyles = makeStyles(auth ? stylesAdmin : stylesLogin)
  const pageLoads = React.useRef({})

  const timeZone = 'America/Sao_Paulo'
  // styles
  const classes = useStyles()
  const wrapper = React.createRef()
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: true,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    })

  const mainPanel = React.createRef()
  const connection = React.createRef()

  React.useEffect(() => {
    connection.current = {
      offline: false
    }
    const verifyLabel = async (hostname) => {
      console.log("WHITELABEL:::", hostname)
      const wl = await apims.post('/WhiteLabel', { url: hostname })
      if (wl) {
        if (wl?.data) {
          setWhiteLabel(wl.data)
          props.reduxFunction("ASYNC", "SET_SESSION", {
            ...session,
            custom: {
              ...session.custom,
              ...wl.data,
            }
          })
        }
        verifySession()
      }
    }
    let hostname = window.location.hostname
    // let hostname = "CLIENTEURL.machen.ai"
    hostname = hostname.replace(".machen.ai", "")

    if (
      hostname
      && hostname.indexOf('software') === -1
      && hostname.indexOf('hom2022') === -1
      && hostname.indexOf('localhost') === -1
    ) {
      verifyLabel(hostname)
    } else {
      verifySession()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  React.useEffect(() => {
    let Nhashs = window.location.hash.split("/")
    Nhashs[0] = Nhashs[0].replace("#", "")
    props.reduxFunction("ASYNC", "SET_DB", {
      ...reduxStore.getState().db,
      history: `${window.location.pathname}${window.location.hash}`
    })
    pageDataRequest(Nhashs)
  })

  React.useEffect(() => {
    let Nhashs = window.location.hash.split("/")
    Nhashs[0] = Nhashs[0].replace("#", "")
    if (auth) {
      pageDataRequest(Nhashs)
      initCompleted()
      init(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  const init = async (force) => {
    connection.current = {
      offline: false
    }
    window.addEventListener("dragover", function (e) {
      e.preventDefault()
    }, false)
    window.addEventListener("drop", function (e) {
      e.preventDefault()
    }, false)

    var exitTime
    var contTime = 0
    var interval_id = setInterval(() => {
      contTime = contTime + 1
    }, 1000)

    var interval_status = setTimeout(() => {

    }, 50000);

    let sessionInterval = setInterval(() => {
      verifySession(true)
    }, 360000)

    window.addEventListener("blur", () => {
      clearInterval(interval_id)
      clearInterval(sessionInterval)
      interval_id = 0
      exitTime = moment().tz(timeZone).unix('X')
      interval_status = setTimeout(() => {
        setStatus("away")
      }, 60000)

    })
    window.addEventListener("focus", async (e) => {
      if (!connection?.current?.offline) {
        verifySession(true)
        clearInterval(sessionInterval)
        sessionInterval = setInterval(() => {
          verifySession(true)
        }, 360000)
        clearTimeout(interval_status)
        if (!interval_id)
          interval_id = setInterval(() => {
            contTime = contTime + 1
          }, 1000)
        setStatus("online")
        if (exitTime && moment().tz(timeZone).unix('X') - exitTime > 30)
          verifyMessages()
      }
    })
  }

  const verifyMessages = (lastDate = null) => {
    loadTimeline(
      {
        ...props,
        fnIdentification: "Layouts/Index/loadData()-Users"
      },
      {
        ids: [reduxStore.getState().session._id],
        db: "users",
        type: "comment",
        newUpdates: true,
        lastDate
      }
    )
  }

  const setStatus = async (status) => {
    if (
      (status === 'online'
        && reduxStore.getState().usersStatus.users
        && reduxStore.getState().usersStatus.users[session._id]
        && reduxStore.getState().usersStatus.users[session._id].status !== 'online'
      )
      ||
      (status === 'away'
        && reduxStore.getState().usersStatus.users
        && reduxStore.getState().usersStatus.users[session._id]
        && reduxStore.getState().usersStatus.users[session._id].status !== 'away'
      )
    ) {
      const { socket } = reduxStore.getState().functions
      socket.emit("socketStatus", {
        socketStatus: status
      })
    }
  }

  const verifySession = async (onlyToken, n = 0) => {
    if (connection.current && !connection.current.offline) {
      if (!onlyToken) {
        try {
          const token = await apims.post('/VerifySession')
          let data = token && token.data && token.data.user && token.data.user.language ? { locale: token.data.user.language } : { all: true }
          if (window.location.hash === "#tab/languages")
            data = { all: true }
          const loadLanguages = await apims.post('/M_Languages_V3', data)

          if (loadLanguages) {
            let languages = await new Promise((resolve, reject) => {
              let result = {}
              loadLanguages.data.forEach((l, li) => {
                result = {
                  ...result,
                  [l.alias.name.toLowerCase()]: {
                    alias: l.alias.name.toLowerCase(),
                    locales: l.locales
                  }
                }
                if (li + 1 === loadLanguages.data.length)
                  resolve(result)
              })
            })

            if (token && token.data && token.data.token) {
              localStorage.setItem('TOKEN', token.data.token)
              props.reduxFunction("ASYNC", "SET_SESSION", {
                ...token.data.user,
                ...token?.data?.user?.GlobalData?.custom ? {
                  custom: {
                    ...session.custom,
                    ...JSON.parse(token?.data?.user?.GlobalData?.custom),
                    custom: token?.data?.user?.GlobalData?.custom,
                    ...whiteLabel,
                  }
                } : {

                }
              })
              props.reduxFunction("ASYNC", "SET_DB", {
                ...reduxStore.getState().db,
                users: {
                  ...token.data.users ? token.data.users : {}
                }
              })
              if (languages && token) {
                props.reduxFunction("ASYNC", "SET_LANGUAGE", {
                  ...languages
                })
                if (!auth || (
                  auth
                  && JSON.stringify(auth) !== JSON.stringify(token.data.user)
                )) {
                  if (JSON.stringify(auth) !== JSON.stringify(token.data.user))
                    setAuth({
                      ...token.data.user,
                    })
                }
                if (!("Notification" in window)) {
                  console.log('Esse browser não suporta notificações desktop')
                } else {
                  if (Notification.permission !== 'denied') {
                    await Notification.requestPermission()
                  }
                }

                if (window.location.pathname === '/' || routes.filter(prop => prop.auth && prop.path === window.location.pathname).length === 0)
                  history.push('/d#tab/resume')
                initSocket()
                loadData()
                return true
              } else {
                props.reduxFunction("ASYNC", "SET_LANGUAGE", {
                  ...languages
                })
                setAuth(false)
              }
            } else {
              props.reduxFunction("ASYNC", "SET_LANGUAGE", {
                ...languages
              })
              if (loading) setLoading(false)
            }
          }
        } catch (err) {
          console.log(err)
          if (n === 5) {
            setOfflineDetected(true)
            if (auth) setAuth(false)
            if (loading) setLoading(false)
            setTimeout(() => {
              window.open("/login", "_self")
            }, 60000)
          } else {
            verifySession(onlyToken, n + 1)
          }
        }
      } else {
        try {
          const token = await apims.post('/VerifySession', { ignoreUsers: true })

          let actualTokenUser = reduxStore.getState().session
          if (token && token.data && token.data.token) {
            localStorage.setItem('TOKEN', token.data.token)
            props.reduxFunction("ASYNC", "SET_SESSION", {
              ...token.data.user,
              ...token?.data?.user?.GlobalData?.custom ? {
                custom: {
                  ...session.custom,
                  ...JSON.parse(token?.data?.user?.GlobalData?.custom),
                  custom: token?.data?.user?.GlobalData?.custom,
                  ...whiteLabel,
                }
              } : {

              }
            })
            const { socket } = reduxStore.getState().functions

            socket.emit("socketStatus", {
              socketStatus: "online"
            })
            if (token.data && actualTokenUser && actualTokenUser._id && token.data.user && actualTokenUser._id !== token.data.user._id) {
              window.location.reload()
              return
            }
          } else {
            localStorage.setItem('TOKEN', null)
            props.reduxFunction("ASYNC", "CLEAR_SESSION")
            if (window.location.pathname !== '/login') {
              setTimeout(() => {
                window.open("/login", "_self")
              }, 300)
            }
          }
        } catch (err) {
          if (n > 5) {
            localStorage.setItem('TOKEN', null)
            props.reduxFunction("ASYNC", "CLEAR_SESSION")
            if (window.location.pathname !== '/login') {
              setTimeout(() => {
                window.open("/login", "_self")
              }, 300)
            }
          } else {
            setTimeout(() => {
              verifySession(onlyToken, n + 1)
            }, 3000)
          }
        }
      }
    } else {
      console.log("OFFLINE")
    }
  }

  const loadData = async () => {
    loadUsersV2(props, null, false)
    try {
      let reqTagsA = await apims.get(`/Tags_List`)
      if (reqTagsA && reqTagsA.data) {
        reqTagsA.data.forEach(x => {
          x._count = parseInt(x._count)
          x._countOthers = parseInt(x._countOthers)
        })
        props.reduxFunction("ASYNC", "SET_DB", {
          ...reduxStore.getState().db,
          tag: reqTagsA.data ? reqTagsA.data : []
        })
      }
    } catch (e) {
      console.group('Layout-ReqTags::ERR::')
      console.log(e)
      console.groupEnd()
    }


    //USERGROUPS
    try {
      let reqGroups = await apims.get(`/M_User_Group_List`)
      if (reqGroups && reqGroups.data) {
        props.reduxFunction("ASYNC", "SET_DB", {
          ...reduxStore.getState().db,
          userGroup: reqGroups.data ? reqGroups.data : []
        })
      }
    } catch (e) {
      console.group('Layout-ReqGrpups::ERR::')
      console.log(e)
      console.groupEnd()
    }
  }


  const initCompleted = async () => {
    await loadCardsV2(props, { cardLoad: 'chat' })
    await loadTimeline(
      {
        ...props,
        fnIdentification: "Layouts/Index/loadData()-Users"
      },
      {
        ids: [reduxStore.getState().session._id],
        db: "users",
        type: "comment",
        ini: true,
        limit: 1
      }
    )

    // await loadTimeline(
    //   {
    //     ...props,
    //     fnIdentification: "Layouts/Index/loadData()-Users"
    //   },
    //   {
    //     ids: [reduxStore.getState().session._id],
    //     db: "users",
    //     type: "comment",
    //     ini: true,
    //   }
    // )
  }
  const pageDataRequest = async (h, r = false) => {
    if (auth && window.location.pathname !== '/' && window.location.pathname !== '/login' && props && props.reduxFunction) {
      if (pageLoads && pageLoads.current && !pageLoads.current.initialLoader) {
        pageLoads.current = {
          initialLoader: true
        }
        let reqForms = await apims.post('/Forms_List')
        if (reqForms && reqForms.data && reqForms.data.length > 0) {
          let forms = {

          }
          reqForms.data.filter(f => f.data._id).forEach(f => {
            forms = {
              ...forms,
              [f.data._id]: {
                ...f.data
              }
            }
          })
          props.reduxFunction("ASYNC", "SET_FORMS", {
            ...forms
          })
        }

        await loadCardsV2(props, {
          cardLoad: 'notifications',
        })

        await loadCardsV2(props, {
          cardLoad: 'myDay',
        })

        await loadCardsV2(props, {
          cardLoad: 'favorites',
        })

        await loadCardsV2({ ...props }, {
          cardLoad: 'plans'
        })
        await loadCardsV2({ ...props }, {
          cardLoad: 'chat',
          ignoreLoader: true
        })
        await loadCardsV2({ ...props }, {
          cardLoad: 'steps',
          // ignoreLoader: true
        })
        await loadCardsV2({ ...props }, {
          cardLoad: 'approval',
          ignoreLoader: true
        })
        await loadCardsV2(props, {
          cardLoad: 'links',
          limit: 9,
          skip: 0
        })
        await loadCardsV2(props, {
          cardLoad: 'insights',
          limit: 9,
          skip: 0
        })
      }
      const request = await pageLoadCards(props)
      if (request) {
        setLoading(false)

        return true
      }
    } else {
      return true
    }
  }

  const handleDrawerToggle = () => {
    // setMobileOpen(!mobileOpen)
  }

  const initSocket = async () => {
    let newSocket = await socketIOClient(API_WS, {
      transports: ['websocket', 'polling'],
      // query: { token: localStorage.getItem('TOKEN') },
      auth: { token: localStorage.getItem('TOKEN') },
    })
    if (newSocket) {
      socketFunctions(newSocket)
      props.reduxFunction("ASYNC", "SET_FUNCTIONS", {
        socket: newSocket
      })

    }
    return true
  }

  const authCallBack = async (d) => {
    if (d.fn === 'login') {
      localStorage.setItem('TOKEN', d.token)
      setTimeout(() => {
        window.open("/d#tab/resume", "_self")
      }, 1000)
    } else if (d.fn === 'alert') {
      appAlert({
        ...d
      })
    }
  }
  const getAuthRoutes = routes => {
    return routes
      .map((prop, key) => {
        if (prop.views)
          return getAuthRoutes(prop.views)

        let CompRender
        CompRender = prop.component
        if (!prop.auth && CompRender) {
          return (
            <Route

              path={prop.path}
              component={() => <CompRender callback={(d) => { authCallBack(d) }} />}
              key={key}
            />
          )
        }
        return null
      })
  }


  const getRoutes = (routes) => {
    const session = auth
    return routes
      .filter(prop => {
        if (prop.auth && (
          !prop.AppAccess
          || (
            prop
            && prop.AppAccess
            && session
            && session._access
          )
        )
        ) {
          let access = !prop.access && !prop.AppAccess ? true : false

          if (prop.access) {
            prop.access.forEach(x => {
              if (session && session[x])
                access = true
            })
          }
          if (prop.AppAccess) {
            prop.AppAccess.forEach(x => {
              if (session && session._access && session._access[x])
                access = true
            })
          }
          if (access)
            return true
        }
        return false
      })
      .map((prop, key) => {
        let CompRender
        CompRender = prop.component
        return (
          prop.views && prop.views.length > 0 && getRoutes(prop.views),
          <Route
            path={prop.path}
            component={() => <div
              style={{
                ...(expanded && (props.search || prop.subMenu)) ? { position: "absolute", left: 48, top: 0, right: 0, bottom: 0, overflow: "auto" } : {}
              }}>
              <ErrorBoundary
                info={{
                  path: "layouts/index",
                  component: "Sidebar",
                }}
              >
                <Hidden xsDown>
                  {expanded &&
                    <Sidebar
                      routes={routes}
                      handleDrawerToggle={handleDrawerToggle}
                      menuExpand={() => { setExpanded(prev => !prev) }}
                      subMenu={(props.search || prop.subMenu) ? true : false}
                      setSubMenuExpanded={(a) => { setSubMenuExpanded(a) }}
                      subMenuExpanded={(props.search || prop.subMenu) && subMenuExpanded ? true : false}
                      {...rest}
                    />
                  }
                </Hidden>
              </ErrorBoundary>
              <CompRender />

            </div>}
            key={key}
            expanded={(props.search || prop.subMenu) && expanded ? true : false}
            subMenu={(props.search || prop.subMenu) && expanded && subMenuExpanded ? true : false}
          />
        )
      })
  }

  /**LOAD DATA */

  const logout = async (sk) => {
    localStorage.setItem('TOKEN', null)
    props.reduxFunction("ASYNC", "CLEAR_SESSION")
    setTimeout(() => {
      window.open("/login", "_self")
    }, 300)
  }

  // const appRoutes = getRoutes(routes)

  const socketFunctions = (socket) => {
    //VERSION
    socket.on('global', data => {
      if (data.fn === 'version') {
        appAlert({
          message: translate(`$__appNewVersionAvaiable`, 1),
          hideiconvariant: true,
          variant: 'info',
          persist: true,
          horizontal: 'right',
          confirm: () => {
            window.location.reload()
          },
          // cancelHide: true
        })
      }
      if (data.fn === 'logout') {
        logout()
      }
      if (data.fn === 'reload') {
        window.location.reload()
      }
    })

    //MATRIX
    socket.on('SWOT.UPD', data => {
      dbUpdateCardSWOT({
        ...props,
        store: reduxStore.getState(),
        _sessionId: session._id,
      }, data)
    })

    //CARDS
    socket.on('copy.LOAD', data => {
      // console.log("copy.LOAD", data)
      //childrens
      loadCardsV2({ ...props, ignoreLoader: true }, { cardLoad: 'childrens', idRel: data._id })
    })
    socket.on('card.ADD', data => {
      if (data._id)
        loadCardsV2({ ...props, ignoreLoader: true }, { cardLoad: 'card', idRel: data._id })
    })


    socket.on('card.UPD', data => {
      // console.log('card.UPD::627', data)
      dbUpdateCard(props, data)
    })

    socket.on('card.MYDAY', data => {
      myDayUpd(props, data)
    })

    socket.on('card.TIMER', data => {
      console.log(data)
      if (data && data.type && data.type === "stop") {
        console.log('card.TIMER', data)
        dbUpdateCardTimer(props, data)
      }
    })


    socket.on('card.UPDATE', data => {
      // console.log('card.UPDATE::632', data)
      dbUpdateCard_V2(props, data)
    })


    socket.on('card.ACCESS_UPD', async data => {
      cardAccessUpdate(props, data)
    })

    socket.on('card.ACCESS_DELETE', data => {
      cardAccessDelete(props, data)
    })

    socket.on('card.ACCESS_REMOVE', data => {
      cardAccessRemove(props, data)
    })

    socket.on('card.REORDER', data => {
      // console.log('card.REORDER::646', data)
      reorderCard(props, data)
    })
    socket.on('card.DELETE', data => {
      // console.log('card.DELETE::650', data)
      deleteCard(props, data, true)
    })
    socket.on('card.unlinkChildrens', data => {
      // console.log('card.unlinkChildrens::654', data)
      socketUnLinkChildrens(props, data)
    })

    socket.on('kanban.REORDER', data => {
      // console.log(data)
      kanbanReorder(props, data)
    })

    socket.on('unlinkChildrens', data => {
      socketUnLinkChildrens(props, data)
    })

    socket.on('card.changeParentId', (data) => {
      let loads = reduxStore.getState().sessionLoads.cards.load
      let reqMd5 = MD5(`${JSON.stringify({
        idRel: data.planId,
        cardLoad: 'plan',
      })}`).toString()

      loadCardsV2({ ...props, ignoreLoader: true }, { cardLoad: 'plan', idRel: data.planId, force: true })
      if
        (
        Object.keys(loads).length > 0 && !loads[reqMd5]
      ) {
        loadCardsV2({ ...props, ignoreLoader: true }, { cardLoad: 'plan', idRel: data.planId, force: true })
      } else {
      }
      changeParentId(props, data)
    })

    //GENERIC ACTIONS BY NODE
    socket.on('node.UPDATE', data => {
      // console.log('node.UPDATE::684', data)
      nodeUpdate(props, data)
    })


    //COLUMNS
    socket.on(`column.UPD`, data => {
      // console.log('node.UPD::690', data)
      updateColumnCards(props, data)
    })
    socket.on(`column.DELETE`, data => {
      // console.log('column.DELETE::694', data)
      deleteColumn(props, data)
    })
    socket.on(`column.REORDER`, data => {
      // console.log('column.REORDER::698', data)
      reorderColumns(props, data)
    })
    socket.on(`column.CARDS`, data => {
      // console.log('column.CARDS::702', data)
      columnCards(props, data)
    })



    //USERS
    socket.on(`user.UPD`, data => {
      dbUpdateUser(props, data)
    })

    socket.on('user.CONNECT', data => {
      props.reduxFunction("ASYNC", "SET_USERS_STATUS", {
        users: {
          ...reduxStore.getState().usersStatus.users,
          [data.user]: {
            ...data,
            status: data.status,
            updatedAt: data.updatedAt,
          }
        },
      })
    })
    socket.on('user.DISCONNECT', data => {
      props.reduxFunction("ASYNC", "SET_USERS_STATUS", {
        users: {
          ...reduxStore.getState().usersStatus.users,
          [data.user]: null
        },
      })
    })
    socket.on('users.STATUS', data => {
      props.reduxFunction("ASYNC", "SET_USERS_STATUS", {
        ...reduxStore.getState().usersStatus,
        users: {
          ...data.users
        }
      })
    })

    socket.on(`user.ACCESS`, data => {
      // console.log('user.ACCESS::892', data)
      dbUpdateUser(props, data)
    })
    socket.on("userGroup.UPD", data => {
      // console.log('userGroup.UPD::896', data)
      tagUpdate(props, data, true)
    })

    //FEEDS
    socket.on(`feed.ADD`, data => {
      // console.log('feed.ADD::902', data)
      dbUpdateFeed(props, data)
    })

    //LANGUAGES
    socket.on('language.UPD', data => {
      // console.log('LANGUAGE.UPD::908', data)
      languageUPD(props, data)
    })

    //TAGS
    socket.on("tag.UPD", data => {
      // console.log('tag.UPD::914', data)
      tagUpdate(props, data, true)
    })
    socket.on("tag.DEL", data => {
      // console.log('tag.DEL::918', data)
      localTagDelete(props, data, true)
    })

    socket.on("tag.UNLINK", data => {
      // console.log('tag.UNLINK::923', data)
      localTagUnlink(props, data, true)
    })


    //LOGOUT
    socket.on("logout", data => {
      // console.log('logout::930', data)
      if (
        data.user === reduxStore.getState().session._id
      ) {
        logout()
      }
    })

    //NOTIFICATIONS
    socket.on("notifications.ADD", async data => {
      // console.log('notifications.ADD::940', data)
      if (reduxStore.getState().session._id === data.userId && data.notify) {
        props.reduxFunction("ASYNC", "SET_NOTIFICATIONS", {
          ...reduxStore.getState().notifications,
          [data.notify._id]: data.notify,
        })
      }
    })


    //CHAT
    socket.on("chat.STATUS", data => {

      // console.log('chat.STATUS::953', data)
      props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        users: {
          ...reduxStore.getState().db.users,
          [data.user]: {
            ...reduxStore.getState().db.users[data.user],
            chatStatus: data.status
          }
        }
      })
    })
    socket.on("chat.READED", data => {

      if (reduxStore.getState().db.cards[data.id] || reduxStore.getState().db.users[data.id] || reduxStore.getState().db.users[data.readedBy]) {
        chatReaded(props, data)
      }
    })
    socket.on("chat.INTERACT", data => {
      // console.log('chat.INTERACT', data)
      interact(props, data)
      if (reduxStore.getState().db.cards[data.id] || reduxStore.getState().db.users[data.id] || reduxStore.getState().db.users[data.readedBy]) {
      }
    })
    let backdropTimer = setTimeout(() => {

    }, 5000)
    socket.on("disconnect", () => {
      connection.current = {
        offline: true
      }
      backdropTimer = setTimeout(() => {
        setBackdropEnabled(true)
      }, 5000)
    })
    socket.on("connect", () => {
      setTimeout(() => {
        socket.emit("data", {
          module: "user",
          method: "post",
          action: "logout"
        },
          {
            notThis: true
          })
      }, 2000)
      if (connection?.current?.offline) {
        connection.current = {
          offline: false
        }
        verifyMessages()
      }
      verifySession(true)
      clearTimeout(backdropTimer)
      setBackdropEnabled(false)
    })
  }

  if (loading) {
    return (
      <div style={{
        position: 'fixed',
        top: '0px',
        bottom: '0px',
        left: '0px',
        right: '0px'
      }}>
        <LoaderPage />
      </div>
    )
  }

  if (offlineDetected && window.location.pathname !== '/offline-detect') {
    history.push('offline-detect')
  }

  if (!offlineDetected && (window.location.pathname === '/maintenance-mode' || window.location.pathname === '/offline-detect')) {
    history.push('login')
  }

  return (
    <div style={{
      position: 'fixed',
      top: '0px',
      bottom: '0px',
      // left: auth && expanded ? `calc(150px + ${subMenuExpanded ? `264px` : `0px`})` : '0px',
      left: auth && expanded ? `calc(150px + ${subMenuExpanded ? `264px` : `0px`})` : '0px',
      right: '0px',
    }}
      onContextMenu={() => {
        // console.log('GENERAL MENU')
      }}
    >
      {auth ? (
        <React.Fragment>
          <div
            className={classes.wrapper}

          >
            {/* <ErrorBoundary>
              <Hidden smUp>
                <MobileNav
                  routes={routes}
                  handleDrawerToggle={handleDrawerToggle}
                  menuExpand={() => { setExpanded(prev => !prev) }}
                  setSubMenuExpanded={(a) => { setSubMenuExpanded(a) }}
                  subMenuExpanded={subMenuExpanded}
                  expanded={expanded}
                  {...rest}
                />
              </Hidden>
            </ErrorBoundary> */}
            <div
              className={mainPanelClasses}
              ref={mainPanel}
              id="mainPannel"
            >
              <div className={classes.container}>
                <ErrorBoundary>
                  <Switch>
                    {getRoutes(routes)}
                    <Redirect from="/" to="/d#tab/resume" />
                  </Switch>
                </ErrorBoundary>
              </div>
            </div>
            <ErrorBoundary>
              <KanbanModal />
            </ErrorBoundary>
            <ErrorBoundary>
              <SideModule />
            </ErrorBoundary>
          </div>
          <Hidden smDown>
            <TopHeader menuExpand={() => { setExpanded(prev => !prev) }} expanded={expanded} />
          </Hidden>
          <ErrorBoundary>
            <LightBox />
          </ErrorBoundary>
          <MiroBoard />
          {/* <Notifier /> */}
          <ErrorBoundary>
            <LicensingAgreement />
          </ErrorBoundary>
          <ErrorBoundary>
            <Backdrop open={backdropEnabled} className={classes.backdrop}
              style={{
                backdropFilter: "blur(7px) sepia(90%)",
              }}
            >
              <div style={{
                position: 'absolute',
                left: '0px',
                right: '0px',
                top: '0px',
                bottom: '0px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#FFFFFF',
                fontSize: '33px',
                flexDirection: 'column'
              }}>
                <div style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <Icon color={'ColumnTitleColor'} size="77px">cloud_off</Icon>
                </div>
                <div style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '15px'
                }}>
                  {translate('$__noInternetConnection', '*')}
                </div>
              </div>
            </Backdrop>
          </ErrorBoundary>
          <div>
            {/* NOTICE: id must be unique between EVERY <ContextMenuTrigger> and <ContextMenu> pair */}
            {/* NOTICE: inside the pair, <ContextMenuTrigger> and <ContextMenu> must have the same id */}

            {/* <ContextMenuTrigger id="same_unique_identifier">
          <div className="well">Right click to see the menu</div>
          </ContextMenuTrigger>
          
          <ContextMenu id="same_unique_identifier">
          <MenuItem data={{ foo: 'bar' }} onClick={this.handleClick}>
          ContextMenu Item 1
          </MenuItem>
          <MenuItem data={{ foo: 'bar' }} onClick={this.handleClick}>
            ContextMenu Item 2
          </MenuItem>
          <MenuItem divider />
          <MenuItem data={{ foo: 'bar' }} onClick={this.handleClick}>
            ContextMenu Item 3
          </MenuItem>
        </ContextMenu> */}

          </div>
          <ErrorBoundary>
            <Chat expanded={expanded} subMenuExpanded={subMenuExpanded} />
          </ErrorBoundary>
          {/* <ErrorBoundary>
            <Notes />
          </ErrorBoundary> */}
          <ErrorBoundary>
            <CardActions />
          </ErrorBoundary>
          <ErrorBoundary>
            <CardAdd />
          </ErrorBoundary>
          <ErrorBoundary>
            <CopyMove />
          </ErrorBoundary>
          <ErrorBoundary>
            <MoveCards />
          </ErrorBoundary>
          <ErrorBoundary>
            <PolicyAndTerms />
          </ErrorBoundary>
          <ErrorBoundary>
            <Tour />
          </ErrorBoundary>
          <Call />
        </React.Fragment>
      ) : (
        <div>
          <div className={classes.wrapper} ref={wrapper}          >
            <AuthNavbar />
            <div
              className={classes.fullPage}
              style={{
                backgroundImage: `url("${whiteLabel?.loginBackground ? whiteLabel.loginBackground : customApp("loginBackground")}")`,
              }}
            >
              <div
                // className={classes.fullPage}
                style={
                  {
                    backgroundImage: "url(" + whiteLabel?.loginBackground ? whiteLabel.loginBackground : customApp("loginBackground") + ")",
                    minHeight: "100vh !important"
                  }
                }
              >

              </div>
              <ErrorBoundary>
                <Switch>
                  {getAuthRoutes(routes)}
                  <Redirect from="*" to={offlineDetected ? '/offline-detect' : '/login'} />
                </Switch>
              </ErrorBoundary>
              <ErrorBoundary>
                <Footer white />
              </ErrorBoundary>
            </div>
          </div>
        </div>
      )
      }
      <ErrorBoundary>
        <StyledContainer pauseOnFocusLoss />
      </ErrorBoundary>
    </div>
  )
}

const StyledContainer = styled(ToastContainer)`
&&&.Toastify__toast-container {
  min-width:407px !important;
  z-index:333333 !important;
}
.Toastify__toast {
  z-index::333333 !important;
  }
.Toastify__toast-body {
  z-index::333333 !important;
  }
.Toastify__progress-bar { }
`;

const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch)

export default connect(null,
  mapDispatchToProps
)(SaasHome)