import api from "api"
import { v4 as uuid } from "uuid";
import moment from "moment-timezone"
import MD5 from "crypto-js/md5"

//REDUX
import reduxStore from "store/"


import {
    cardsAccess,
    isResponsible as setResponsible
} from "functions/users"

import { updateCardData, getCardColor, filterCards } from "functions/cards";

const addColumn = async (newData) => {
    let data = {
        ...newData
    }
    Object.keys(data).forEach(x => {
        if (!data[x])
            delete (data[x])
    })

    let newCards = {}
    let users = {}
    if (data.users && data.users.length > 0)
        data.users.forEach((uid, i) => {
            let u = reduxStore.getState().db.users[uid]
            users = {
                ...users,
                [uid]: {
                    _id: uid,
                    name: u.name,
                    image: u.image || null,
                    ...i === 0 ? { isResponsible: true } : {},
                }
            }
        })


    let newId = uuid()

    api.post('card/add', {
        _id: newId,
        ...data,
    });

    newCards = {
        ...newCards,
        [newId]: {
            _id: newId,
            ...data,
            _users: users,
        }
    }

    if (newCards[newId]?.users)
        delete newCards[newId].users

    return newCards
}

const reorder = async (cards, orderBy = 'order', n = 0) => {
    try {
        api.post('kanban/reorder', { cards, orderBy });
        return true
    } catch (e) {
        if (n < 3)
            setTimeout(() => {
                reorder(cards, orderBy, n + 1)
            }, 3000)
    }
}

export const kanbanReorder = (props, data) => {
    let cards = {
        ...reduxStore.getState().db.cards
    }
    if (data.cards && data.cards.length > 0)
        data.cards.forEach((card, i) => {
            cards = {
                ...cards,
                [card]: {
                    ...cards[card],
                    step: `${i + 1}`
                }
            }
        })
    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        cards: {
            ...reduxStore.getState().db.cards,
            ...cards
        }
    })

}

const linkCard = async (data) => {
    try {
        await api.put('card/link', data);
        return true
    } catch (e) {
        setTimeout(() => {
            linkCard(data)
        }, 3000)
    }
}

export const automation = (props, nodeId, to, createdBy) => {
    const { session } = reduxStore.getState()
    //LINK
    let parentCard = reduxStore.getState().db.cards[to]
    let card = reduxStore.getState().db.cards[nodeId]

    let dataUpdate = {}

    if (
        parentCard
        && parentCard.automationDueDateType
        && parentCard.automationDueDateType === "add"
    )
        dataUpdate = {
            endDate: moment(`${moment().format("YYYY-MM-DD")} 23:59:59`).add(parentCard?.automationDueDate || 1, "days").unix("X"),
            ...!card.startDate ? { startDate: moment().unix("X") } : {}
        }

    if (parentCard
        && parentCard.automationStatusType
        && parentCard.automationStatusType === "replace"
    )
        dataUpdate = {
            ...dataUpdate,
            status: parentCard.automationStatus || 'notStarted',
            ...parentCard.automationStatus && parentCard.automationStatus === 'completed' ? { completed_at: moment().unix('x') } : {}
        }
    let usersToRemove = []
    let isResponsible
    if (parentCard.execAsResponsible) {
        isResponsible = session._id
        if (!card._users[session._id])
            cardsAccess(props, session._id, "4", card._id)
    } else if (parentCard
        && parentCard.automationUsers
        && parentCard.automationUsers === 'add'
        && parentCard._users
        && Object.keys(parentCard._users).filter(a => a).length > 0
    )
        Object.keys(parentCard._users).filter(a => a).forEach(id => {
            if (parentCard._users[id].isResponsible)
                isResponsible = id
            if (card && (!card._users || (card._users && !card._users[id]))) {
                const noNotification = card._users && card._users[id] ? true : false
                cardsAccess(props, id, parentCard._users[id].access, card._id, noNotification)
            }
        })



    if (parentCard
        && parentCard.automationTags
        && parentCard.automationTags !== "doNotChange"
        && parentCard._tags
        && parentCard._tags.length > 0
    ) {
        let newTags = card._tags ? Array.from(card._tags) : []

        if (parentCard.automationTags === "replace") {
            let removeTags = []
            let addTags = []
            parentCard._tags.forEach(nt => {
                if (newTags.findIndex(tag => tag.value === nt.value) > -1) {
                    removeTags.push(nt.value)
                    newTags.splice(newTags.findIndex(tag => tag.value === nt.value), 1)
                }
            })
            parentCard._tags.forEach(nt => {
                if (newTags.findIndex(tag => tag.value === nt.value) === -1) {
                    newTags.push(nt)
                    if (addTags.indexOf(nt.value) === -1)
                        addTags.push(nt.value)
                }
            })
            dataUpdate = {
                ...dataUpdate,
                ...removeTags.length > 0 ? { removeTags: removeTags } : {},
                ...addTags.length > 0 ? { addTags: addTags } : {},
            }

            newTags = Array.from(parentCard._tags)
        }

        if (parentCard.automationTags === "add") {
            let addTags = []
            parentCard._tags.forEach(nt => {
                if (newTags.filter(tag => tag.value === nt.value).length === 0) {
                    addTags.push(nt.value)
                    newTags.push(nt)
                }
            })
            dataUpdate = {
                ...dataUpdate,
                addTags: addTags
            }
        }

        if (parentCard.automationTags === "remove") {
            let removeTags = []
            parentCard._tags.forEach(nt => {
                if (newTags.findIndex(tag => tag.value === nt.value) > -1) {
                    removeTags.push(nt.value)
                    newTags.splice(newTags.findIndex(tag => tag.value === nt.value), 1)
                }
            })
            dataUpdate = {
                ...dataUpdate,
                removeTags: removeTags
            }
        }

        dataUpdate = {
            ...dataUpdate,
            _tags: newTags
        }
    }

    if (parentCard
        && parentCard.automationUsers
        && parentCard.automationUsers === 'remove'
        && parentCard._users
        && Object.keys(parentCard._users).filter(a => a).length > 0
    )
        Object.keys(parentCard._users).filter(a => a).forEach(id => {
            if (
                card
                && card._users
                && card._users[id]
                && usersToRemove.indexOf(id) === -1
            )
                usersToRemove.push(id)
        })
    if (parentCard
        && parentCard.automationUsers
        && parentCard.automationUsers === 'removeAll'
    ) {
        if (card && card._users && Object.keys(card._users).filter(a => a).length > 0)
            Object.keys(card._users).filter(a => a).forEach(id => {
                if (usersToRemove.indexOf(id) === -1 && id !== createdBy)
                    usersToRemove.push(id)
            })
        isResponsible = createdBy
    }
    if (parentCard
        && parentCard.automationUsers
        && parentCard.automationUsers === 'replace'
    ) {
        if (parentCard._users && Object.keys(parentCard._users).length > 0)
            Object.keys(parentCard._users).filter(a => a).forEach(id => {
                if (parentCard._users[id].isResponsible)
                    isResponsible = id
                if (card && (!card._users || (card._users && !card._users[id]))) {
                    const noNotification = card._users && card._users[id] ? true : false
                    cardsAccess(props, id, parentCard._users[id].access, card._id, noNotification)
                }
            })
        if (card && card._users && Object.keys(card._users).filter(a => a).length > 0)
            Object.keys(card._users).filter(a => a).forEach(id => {
                if (
                    !parentCard._users[id]
                    && usersToRemove.indexOf(id) === -1
                )
                    usersToRemove.push(id)
            })
    }


    if (
        parentCard
        && parentCard.keepUserWhoCreatedActivity
        && createdBy
        && usersToRemove.indexOf(createdBy) > -1
    )
        usersToRemove.splice(usersToRemove.indexOf(createdBy), 1)


    if (usersToRemove.length > 0)
        api.put(`cards/removeAccess/`, {
            cardId: card._id,
            userIds: usersToRemove
        })
    if (isResponsible)
        setResponsible(props, {
            cardId: card._id,
            userId: isResponsible
        })
    if (parentCard.setIcon)
        dataUpdate = {
            ...dataUpdate,
            icon: parentCard.setIcon
        }
    if (JSON.stringify(dataUpdate) !== "{}")
        automationExec({
            _id: nodeId,
            ...dataUpdate
        })

    if (nodeId && to && reduxStore.getState().db.cards[to] && reduxStore.getState().db.cards[nodeId])
        if (to.length === 36)
            linkCard({
                idRel: nodeId,
                destination: to,
            })

    if (dataUpdate && dataUpdate.status && dataUpdate.status === 'completed' && !dataUpdate.completed_at)
        dataUpdate = {
            ...dataUpdate,
            completed_at: moment().unix('x')
        }
    return dataUpdate
}

const automationExec = async (data) => {
    let newData = {
        ...data,
    }
    let response = await api.post('kanban/automation', newData);
    return response
}

const getKanbanData = (id, options = {}, cards = reduxStore.getState().db.cards, search) => {
    let columns = {}
    let columnsOrder = []
    if (
        cards
        && Object.keys(cards).length > 0
        && Object.keys(cards)
            .filter(card =>
                cards[card]._parent === id
                && cards[card].type === 'step'
                && !cards[card].deleted
            ).length > 0
    )
        Object.keys(cards)
            .filter(card =>
                cards[card]._parent === id
                && cards[card].type === 'step'
                && !cards[card].deleted
                // && cards[card].name === "BACKLOG"
            ).sort((c1, c2) => {
                let step1 = cards[c1].step || 1
                let step2 = cards[c2].step || 1
                if (parseInt(step1) < parseInt(step2))
                    return -1
                if (parseInt(step1) > parseInt(step2))
                    return 1
                return 0
            }).forEach((column, i) => {
                let items = [] //Aqui colocar todos os cards da coluna
                if (Object.keys(cards).length > 0)
                    Object.keys(cards).filter(cid => {
                        if (
                            (
                                cards[cid]._parent === column
                                && (
                                    (
                                        !options.deleted
                                        && !cards[cid].deleted
                                    )
                                    ||
                                    (
                                        options.deleted
                                    )
                                )
                            )
                            ||
                            (
                                i === 0
                                && cards[cid]._parent === id
                                && !cards[cid].deleted
                                && (
                                    cards[cid].type !== 'objective'
                                    && cards[cid].type !== 'goal'
                                    && cards[cid].type !== 'step'
                                    && cards[cid].type.indexOf('guideline') === -1
                                    && cards[cid].type !== 'link'
                                    && cards[cid].type !== 'insight'
                                    && cards[cid].type !== 'threat'
                                    && cards[cid].type !== 'strength'
                                    && cards[cid].type !== 'weakness'
                                    && cards[cid].type !== 'opportunity'
                                    && cards[cid].type !== 'chatGroup'
                                )
                            )
                        ) return true
                        return false
                    }
                    )
                        .sort((ca, cb) => {
                            if (!options.order || options?.order === "order") {
                                let orderA = 0
                                let orderB = 0
                                if (cards[ca]?.order)
                                    orderA = String(cards[ca]?.order?.low ? cards[ca]?.order?.low : cards[ca]?.order)
                                if (cards[cb]?.order)
                                    orderB = String(cards[cb]?.order?.low ? cards[cb]?.order?.low : cards[cb]?.order)
                                if (parseInt(orderA) < parseInt(orderB))
                                    return -1
                                if (parseInt(orderA) > parseInt(orderB))
                                    return 1
                            }
                            return 0
                        })
                        .forEach((cid, cIndex) => {
                            items.push({
                                ...cards[cid],
                                order: String(cIndex)
                            })
                        })
                columns = {
                    ...columns,
                    [column]: {
                        id: column,
                        name: cards[column].name,
                        icon: cards[column].icon || null,
                        items: items,
                    }

                }
                if (columnsOrder.indexOf(column) === -1)
                    columnsOrder.push(column)
            })

    const filterItems = (items) => {
        if (search.text && search.text !== undefined && search.text !== null && search.text !== '')
            items = Array.from(items.filter(a =>
                a.name.toLowerCase().indexOf(search.text.toLowerCase()) > -1
                || a.description?.toLowerCase().indexOf(search.text.toLowerCase()) > -1
            ))
        if (search.startDate && search.startDate !== undefined && search.startDate !== null && search.startDate !== '')
            items = Array.from(items.filter(a =>
                parseInt(a.startDate) >= parseInt(search.startDate)
            ))

        if (search.endDate && search.endDate !== undefined && search.endDate !== null && search.endDate !== '')
            items = Array.from(items.filter(a =>
                parseInt(a.endDate) <= parseInt(search.endDate)
            ))
        if (search.tags && search.tags && search.tags.filter(a => a.value).length > 0)
            items = Array.from(items.filter(fil =>
                fil._tags && fil._tags.length > 0 && fil._tags.filter(tag => {
                    let result = false
                    search.tags.forEach(tagFilter => {
                        if (tagFilter.value === tag.value)
                            result = true
                    })
                    return result
                }
                ).length > 0
            ))
        items = items.filter(fil => {
            if (!fil.status || (fil && fil.status && search.status && search.status[fil.status]))
                return true
            return false
        }).map(res => res)

        items = items.filter(fil => {
            if ((fil && fil.priority && search.priority && search.priority[fil.priority]) || (search.priority['null'] && !fil.priority))
                return true
            return false
        }).map(res => res)

        items = items.filter(fil => {
            if ((fil && fil.complexity && search.complexity[fil.complexity]) || (search.complexity['null'] && !fil.complexity))
                return true
            return false
        }).map(res => res)

        items = items.filter(fil => {
            if ((fil && fil.risk && search.risk[fil.risk]) || (search.risk['null'] && !fil.risk))
                return true
            return false
        }).map(res => res)

        items = items.filter(fil => {
            if ((fil && fil.impact && search.impact[fil.impact]) || (search.impact['null'] && !fil.impact))
                return true
            return false
        }).map(res => res)

        if (search.users && search.users.length > 0)
            items = items.filter(fil => search.users.filter(uid => fil && fil._users && fil._users[uid]).length > 0).map(res => res)
        return items
    }
    if (search) {
        Object.keys(columns).forEach(col => {
            columns[col].items = filterItems(columns[col].items)
        })
    }

    return {
        columns: columns,
        columnOrder: columnsOrder
    }
}

export const getKanbanDataV2 = (id, options = {}, cards = reduxStore.getState().db.cards) => {
    let columns = {}
    let columnsOrder = []
    if (
        cards
        && Object.keys(cards).length > 0
        && Object.keys(cards)
            .filter(card =>
                cards[card]._parent === id
                && cards[card].type === 'step'
                && !cards[card].deleted
            ).length > 0
    )
        Object.keys(cards)
            .filter(card =>
                cards[card]._parent === id
                && cards[card].type === 'step'
                && !cards[card].deleted
            ).sort((c1, c2) => {
                let step1 = cards[c1].step || 1
                let step2 = cards[c2].step || 1
                if (parseInt(step1) < parseInt(step2))
                    return -1
                if (parseInt(step1) > parseInt(step2))
                    return 1
                return 0
            }).forEach((column, i) => {
                let items = [] //Aqui colocar todos os cards da coluna
                if (Object.keys(cards).length > 0)
                    Object.keys(cards).filter(cid =>
                        (
                            cards[cid]._parent === column
                            && (
                                (
                                    !options.deleted
                                    && !cards[cid].deleted
                                )
                                ||
                                (
                                    options.deleted
                                )
                            )
                        )
                        ||
                        (
                            i === 0
                            && cards[cid]._parent === id
                            && !cards[cid].deleted
                            && (
                                cards[cid].type !== 'objective'
                                && cards[cid].type !== 'goal'
                                && cards[cid].type !== 'step'
                                && cards[cid].type.indexOf('guideline') === -1
                                && cards[cid].type !== 'link'
                                && cards[cid].type !== 'insight'
                                && cards[cid].type !== 'threat'
                                && cards[cid].type !== 'strength'
                                && cards[cid].type !== 'weakness'
                                && cards[cid].type !== 'opportunity'
                                && cards[cid].type !== 'chatGroup'
                            )
                        )
                    )
                        .sort((ca, cb) => {
                            let orderA = 0
                            let orderB = 0
                            if (cards[ca]?.order)
                                orderA = String(cards[ca]?.order?.low ? cards[ca]?.order?.low : cards[ca]?.order)

                            if (cards[cb]?.order)
                                orderB = String(cards[cb]?.order?.low ? cards[cb]?.order?.low : cards[cb]?.order)
                            if (parseInt(orderA) < parseInt(orderB))
                                return -1
                            if (parseInt(orderA) > parseInt(orderB))
                                return 1
                            return 0

                        })
                        .forEach((cid) => {
                            items.push(cid)
                        })
                columns = {
                    ...columns,
                    [column]: {
                        id: column,
                        name: cards[column].name,
                        icon: cards[column].icon || null,
                        items: items,
                    }

                }
                if (columnsOrder.indexOf(column) === -1)
                    columnsOrder.push(column)
            })
    return {
        columns: columns,
        columnOrder: columnsOrder
    }
}

export const action = async (props) => {
    const {
        nodeId,
        reason = null,
        approval = false,
        approved = false
    } = props
    const card = reduxStore.getState().db.cards[nodeId]
    const flowCard = reduxStore.getState().db.cards[card._parent]

    // return
    let moveTo
    if (flowCard.onConcludedStepMoveTo) {
        moveTo = flowCard.onConcludedStepMoveTo
    } else {
        moveTo = Object.keys(reduxStore.getState().db.cards).filter(c =>
            reduxStore.getState().db.cards[c]._parent === flowCard._parent
            && reduxStore.getState().db.cards[c].type === "step"
            && parseInt(reduxStore.getState().db.cards[c].step) === parseInt(flowCard.step) + 1
        )[0]
    }

    const nextStep = reduxStore.getState().db.cards[moveTo]


    if (approval && flowCard._id) {
        let dataMessage = {
            _id: uuid(),
            idRel: nodeId,
            cardId: flowCard._id,
            message: `${approved ? `$__approved` : `$__rejected`} - ${reason ? `$__reason: ${reason}` : ``}`,
            type: 'log',
            filesCount: 0,
            approval: true,
            db: 'cards'
        }

        api.post("timeline/add", dataMessage)


        if (!approved && flowCard._parent) {
            if (flowCard._parent)
                linkCard({
                    idRel: nodeId,
                    destination: flowCard._parent,
                })
            props.reduxFunction("ASYNC", "SET_DB", {
                ...reduxStore.getState().db,
                cards: {
                    ...reduxStore.getState().db.cards,
                    [nodeId]: {
                        ...reduxStore.getState().db.cards[nodeId],
                        _parent: flowCard._parent,
                        requireApproval: null
                    }
                }
            })
        }
    }

    if (!approval || (approval && approved)) {
        let id = reduxStore.getState().db.cards[nodeId] && reduxStore.getState().db.cards[nodeId]._createdBy && reduxStore.getState().db.cards[nodeId]._createdBy._id ? reduxStore.getState().db.cards[nodeId]._createdBy._id : null
        let dataUpdate = automation(props, nodeId, moveTo, id)
        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                [nodeId]: {
                    ...reduxStore.getState().db.cards[nodeId],
                    ...dataUpdate,
                    _parent: flowCard.onConcludedStepMoveTo,
                    ...nextStep?.requireApproval ? { requireApproval: true } : { requireApproval: null }
                }
            }
        })
    }
}

export {
    automationExec,
    addColumn,
    getKanbanData,
    linkCard,
    reorder,
}
