import React, { useEffect, useState } from 'react'
import IconComponent from 'components/Icon'

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store";

//functions
import { translate, deleteNode, appAlert } from 'functions';
import { setMyDay, deleteCard, getChildrens } from "functions/cards"

//COMPONENTS
import EditModal from './EditModal';



const SelectedMenu = (props) => {
    const [selectedCards, setSelectedCards] = React.useState({
        checkSelectedCards: [],
        childrenChecked: []
    })
    const [edit, setEdit] = React.useState(false)

    useEffect(() => {
        setSelectedCards(props.selectCards)
    }, [props.selectCards]);

    const addMyday = () => {

        appAlert({
            message: `Adicionar atividades ao meu dia? `,
            variant: 'info',
            persist: true,
            horizontal: 'right',
            confirm: () => {
                const sessionId = reduxStore.getState().session._id
                const cards = [...selectedCards.checkSelectedCards, ...selectedCards.childrenChecked]

                cards.forEach((cardID) => {
                    setMyDay(props, {
                        _id: cardID,
                        userId: sessionId,
                        option: true
                    })

                })
                appAlert({
                    message: `Atividades adicionadas ao meu dia`,
                    variant: 'info',
                    persist: false,
                    horizontal: 'right',
                    confirm: null
                })
            }
        })
    }


    const deleteCards = () => {

        const cards = [...selectedCards.checkSelectedCards, ...selectedCards.childrenChecked]
        appAlert({
            message: `Excluir atividades`,
            variant: 'warning',
            persist: true,
            horizontal: 'right',
            confirm: () => {
                cards.forEach((cardID) => {
                    deleteCard(props, { _id: cardID }, false)
                    deleteNode(props, { _id: cardID }, false)
                })
                props.reduxFunction("ASYNC", "CLEAR_MODULE");

                setSelectedCards({
                    checkSelectedCards: [],
                    childrenChecked: []
                })
                appAlert({
                    message: `Atividades excluidas`,
                    variant: 'info',
                    persist: false,
                    horizontal: 'right',
                    confirm: null
                })
                props.handleState()
            }
        })
    }


    return (
        <div>
            {selectedCards.checkSelectedCards.length > 0 && <div
                style={{
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    height: '60px',
                    position: 'fixed',
                    top: '0px',
                    backgroundColor: '#eeeeee',
                    borderBottom: "1px solid #d1d1cf",
                    opacity: '1',
                    zIndex: '50'
                }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-300px', position: "relative" }}>
                    <p style={{ position: "absolute", left: "-197px", top: "20px", fontWeight: "bold", fontSize: "16px" }}>{translate("$__selectedActivities")} {[...props.selectCards.checkSelectedCards, ...props.selectCards.childrenChecked].length}</p>
                    <div style={{ display: "flex", justifyContent: "center", marginLeft: '10px', cursor: "pointer" }}
                        onMouseOver={(e) => e.target.style.backgroundColor = "rgba(0, 0, 0, 0.12)"}
                        onMouseOut={(e) => e.target.style.backgroundColor = "rgba(0, 0, 0, 0)"}
                        onClick={() => {
                            props.reduxFunction("ASYNC", "SET_CONTROL", {
                                ...reduxStore.getState().control,
                                selectedCards: selectedCards.checkSelectedCards,
                            })
                            setSelectedCards({
                                checkSelectedCards: [],
                                childrenChecked: []
                            })
                            props.handleState()
                            props.handleCheckBoxState()
                        }}>
                        <IconComponent
                            title={translate("$__move")}
                            style={{ width: "40px", height: "40px" }}
                            size={30} >send</IconComponent>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginLeft: '10px', cursor: "pointer" }}
                        onMouseOver={(e) => e.target.style.backgroundColor = "rgba(0, 0, 0, 0.12)"}
                        onMouseOut={(e) => e.target.style.backgroundColor = "rgba(0, 0, 0, 0)"}
                        onClick={() => {
                            setEdit(true)
                            props.handleCardsUpdate(false)
                        }}>
                        <IconComponent title={"edit"} size={30} style={{ width: "40px", height: "40px" }}>edit</IconComponent>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginLeft: '10px', cursor: "pointer" }}
                        onMouseOver={(e) => e.target.style.backgroundColor = "rgba(0, 0, 0, 0.12)"}
                        onMouseOut={(e) => e.target.style.backgroundColor = "rgba(0, 0, 0, 0)"}
                        onClick={() => {
                            deleteCards()
                        }}>
                        <IconComponent title={translate("$__deleteCards")} size={30} style={{ width: "40px", height: "40px", color: "red" }}>delete</IconComponent>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginLeft: '10px', cursor: "pointer" }}
                        onMouseOver={(e) => e.target.style.backgroundColor = "rgba(0, 0, 0, 0.12)"}
                        onMouseOut={(e) => e.target.style.backgroundColor = "rgba(0, 0, 0, 0)"}
                        onClick={() => {
                            addMyday()
                        }}>
                        <IconComponent title={translate("$__addMyDay")} size={30} style={{ width: "40px", height: "40px", color: "lightblue" }}>alarm_add</IconComponent>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginLeft: '10px', cursor: "pointer" }}
                        onMouseOver={(e) => e.target.style.backgroundColor = "rgba(0, 0, 0, 0.12)"}
                        onMouseOut={(e) => e.target.style.backgroundColor = "rgba(0, 0, 0, 0)"}
                        onClick={() => {
                            setSelectedCards({
                                checkSelectedCards: [],
                                childrenChecked: []
                            })
                            props.handleState()
                        }}>
                        <IconComponent title={translate("$__cancel")} size={30} style={{ width: "40px", height: "40px", color: "grey" }}>close</IconComponent>
                    </div>
                </div>
            </div>}
            {
                edit && <><div
                    onClick={(e) => {
                        setEdit(prev => !prev)
                        e.stopPropagation();
                    }}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backdropFilter: 'blur(2px)',
                        zIndex: 98,
                    }}>
                </div>
                    <div style={{
                        position: 'absolute',
                        borderRadius: "5px",
                        left: 'calc(50% - 65px)',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: "white",
                        width: "540px",
                        zIndex: 99
                    }}>
                        <EditModal
                            handleCardsUpdate={(value) => props.handleCardsUpdate(value)}
                            cards={[...props.selectCards.checkSelectedCards, ...props.selectCards.childrenChecked]}
                            handleClose={() => setEdit(prev => !prev)} nodeID={reduxStore.getState().db.cards[props.nodeId]}
                        />

                    </div>
                </>
            }
        </div >
    )
}

const mapStateToProps = (store, props) => ({
    controls: store.controls,
    cards: store.db.cards
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectedMenu);